/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { getDeletePostMsg, remakeBodyStrInList } from '../../../util/Global';
import { CANT_READ_POST_ON_DELETE, IMG_URL_CHOICE, POST_CUT_YEAR } from '../../../util/ConstValue';
import { PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_POST_PARAMS, PAGE_URL, getStartPage, makeBottomPageNumberList, makeUrl } from '../../../util/Page';
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux_saga/modules';


function PostListComponent( props:any ) {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [itemList, setItemList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

	const [tabType, setTabType] = useState(API_TYPES.POST_TYPE.ALL);
    const [searchType, setSearchType] = useState(API_TYPES.SEARCH_TYPE.ALL);
	
    const [searchStr, setSearchStr] = useState("");
	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);

	

	useEffect(()=>{
		const l_p = new URLSearchParams(location.search);
		const s = l_p.get("search");
		const t = l_p.get("type");
		const p = l_p.get("page");

		var newYear = (new Date()).getFullYear();
        var newSearch = "";
        var newType = API_TYPES.SEARCH_TYPE.ALL;
        var newPage = 1;
        var isNewSearch = false;

		if( s ) 	{ try{ newSearch = s; 		    }catch(e){ console.log("e",e);} }
        else { 
            alert("검색어가 없습니다");
            navigate(PAGE_URL.MAIN, {replace:true});
        }

		if( t ) 	{ try{ newType 	 = parseInt(t); }catch(e){ console.log("e",e);} }
		
        if( p ) 	{ try{ newPage 	 = parseInt(p); }catch(e){ console.log("e",e);} }
        else { isNewSearch = true; }

		setYear(newYear);
        setSearchStr(newSearch);
        setSearchType(newType);
        setPage(newPage);
		setTabType(API_TYPES.POST_TYPE.ALL);
		
		reqList( newYear, newPage, newType, API_TYPES.POST_TYPE.ALL, newSearch, isNewSearch);

		return(()=>{})
	},[location]);

	

	const reqList = ( year:number, page:number, searchType:number, tabType:number, searchStr:string, isNewSearch:boolean = false ) => {

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				setItemList( resData.list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		}

		if( isNewSearch ) {
			apiAxios.global.listSearch(dispatch, searchType, searchStr, callback);
		}

		if( tabType == API_TYPES.POST_TYPE.ALL ) {
			apiAxios.global.listAllPost( dispatch, year, page, searchType, searchStr, callback);
		}
		else if( tabType == API_TYPES.POST_TYPE.STORY ) {
			apiAxios.global.listStoryAllPost( dispatch, year, page, searchType, searchStr, callback);
		}
		else if( tabType == API_TYPES.POST_TYPE.COMMUNITY ) {
			apiAxios.global.listCommunityAllPost( dispatch, year, page, searchType, searchStr, callback);
		}
	}

	var searchTypeStr = searchType==API_TYPES.SEARCH_TYPE.TITLE?"제목 ":searchType==API_TYPES.SEARCH_TYPE.WRITER?"작성자 ":"전체 ";

	return(
		<>
			<div className='search_top'><span>'</span><span className='color_red bold'>{searchStr}</span><span>{"' 에 대한 " + searchTypeStr + "검색결과"}</span></div>
			<div className='home__post_area' id="home__post_area">
				<TabComponent {...props} currentType={tabType} setCurrentType={(type:number)=>{
					var y = (new Date()).getFullYear();

					setTabType(type);
					setYear(y);
					setPage(1);

					
					reqList( y, 1, searchType, type, searchStr, false);
				}}/>
				<PostComponent {...props} 
					currentType={tabType}
					itemList={itemList}
					setItemList={setItemList}
					totalData={totalData}
					setTotalData={setTotalData}
					searchStr={searchStr}
					searchType={searchType}
					year={year}
					page={page}
				/>
			</div>
			<PageComponent {...props}
				totalData={totalData}
				year={year}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA)=>{
					var y = year;
					var p = page;
					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						p = item.page;
						setPage( p );
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						p = getStartPage(page) - 10;
						setPage( p );
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						p = getStartPage(page) + 10;
						setPage( p );
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR ) { 
						p = 1;
						y = item.page;
						setYear( y );
						setPage(p);
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR ) { 
						p = 1;
						y = item.page;
						setYear( y );
						setPage(p);
					 }
					 reqList( y, p, searchType, tabType, searchStr, false);
					 const ele : any = document.getElementById("home__post_area");
					const elePosition = window.scrollY + ele.getBoundingClientRect().top;
					window.scrollTo(0,elePosition-20);
				}}
			/>
		</>
	)
}
export default PostListComponent;

function PageComponent(props:any) {
	const {totalData, year, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, year );
		setPageList(list);

        return(()=>{})
    },[totalData, year, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='home__post_page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 			{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 		{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 		{ numberStr = "다음"; 			className="next"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && POST_CUT_YEAR )	{ numberStr = item.page+"년"; 	className="before_year";  yearStyle={width:100}}
				else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && POST_CUT_YEAR )	{ numberStr = item.page+"년"; 	className="next_year";  	yearStyle={width:100}}

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						{ item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && item.page != -1 && POST_CUT_YEAR &&
							<span className="dot">{"·"}</span>
						}
						<span className="page" onClick={()=>{ if( onClickPage ) { onClickPage(item); } }}>
							{item.page != -1?numberStr:""}
						</span>

						{ item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && item.page != -1 && POST_CUT_YEAR &&
							<span className="dot">{"·"}</span>
						}
					</div>
				)
			})}
		</div>
	)
}

function TabComponent( props:any ) {
	return(
		<div className='tab_box'>
			<TabItem {...props} name="전체" tabType={API_TYPES.POST_TYPE.ALL}/>
			<TabItem {...props} name="사연" tabType={API_TYPES.POST_TYPE.STORY}/>
			<TabItem {...props} name="커뮤니티" tabType={API_TYPES.POST_TYPE.COMMUNITY}/>
		</div>
	)
}

function TabItem( props:any ) {
	const {name, currentType, setCurrentType, tabType} = props;

	const clickedTab = () => {
		if( currentType != tabType ) {
			setCurrentType(tabType);
		}
	}

	var tabClassName = "tab_item";
	if( currentType == tabType ) {
		tabClassName = tabClassName + " select";
	}
	return(
		<div className={tabClassName} onClick={clickedTab}>
			<span>{name}</span>
		</div>
	)
}

function PostComponent( props:any ) {
	const { currentType, itemList, searchStr, searchType, year } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);

	const dispatch = useDispatch();
	const navigate = useNavigate();

    /*useEffect(()=>{
		reqList();

        return(()=>{})
    },[currentType, year, page]);*/

	const clickedPost = ( item:any ) => {
		var post_type = currentType==API_TYPES.POST_TYPE.ALL?item.post_type:currentType;
		var url = "";
		if( post_type == API_TYPES.POST_TYPE.STORY ) 		url = PAGE_URL.STORY_POST;
		else if( post_type == API_TYPES.POST_TYPE.COMMUNITY ) url = PAGE_URL.COMMUNITY_POST;

		// 사용가능 상태가 아니고 관리자 로그인이 아니라면 접근 불가
		if( item.use_state != API_TYPES.POST_USE_STATE.ABLE && (!loginData||loginData.is_admin!=API_TYPES.BOOLEAN.TRUE) ) {
			alert(CANT_READ_POST_ON_DELETE);
			return;
		}

		
        const params : PAGE_POST_PARAMS = {
            idx:item.idx,
            year:item.year,
            post_list_type:currentType,
            post_type:post_type,
            category:item.cate_idx,
            search:searchStr,
			searchType:searchType
        }
        var fullUrl = makeUrl(url, params);
        navigate(fullUrl);
	}

	return(
		<div className="post_box">
			{ !!itemList && itemList.length > 0 &&
				<ul>
					{itemList.map((item:any, index:number)=>{
						return(
							<PostItem {...props} key={index} item={item} onClickPost={clickedPost}/>
						)
					})}
				</ul>
			}
			{ (!itemList || itemList.length == 0) &&
				<div className='empty'>{(POST_CUT_YEAR?year+"년 ":"") + "게시글이 없습니다"}</div>
			}
		</div>
	)
}

function PostItem( props:any ) {
	const {currentType, item, onClickPost} = props;

	const haveImage = !!item.image01;
	const isBest = item.best == API_TYPES.BOOLEAN.TRUE;
    const isChoice = item.choice_show == API_TYPES.BOOLEAN.TRUE;

    const haveReplyNum = item.reply_num && item.reply_num > 0;
	const titleMaxWidth = 940 - (haveImage?150:0) - (isBest?60:0) - (haveReplyNum?60:0) - (isChoice?120:0);
	const bodyMaxWidth = 940 - (haveImage?150:0) - (isChoice?120:0);

	var postName = "";
	var postType = currentType==API_TYPES.POST_TYPE.ALL?item.post_type:currentType;

	if( currentType==API_TYPES.POST_TYPE.ALL && postType==API_TYPES.POST_TYPE.STORY ) { postName = "사연 · "; }
	else if( currentType==API_TYPES.POST_TYPE.ALL && postType==API_TYPES.POST_TYPE.COMMUNITY ) { postName = "커뮤니티 · "; }

	const bodyStyle = haveImage||isChoice?{maxWidth:bodyMaxWidth, height:40}:{maxWidth:bodyMaxWidth}; // 고정 바디 값이 있는가?


	if( item.use_state!=API_TYPES.POST_USE_STATE.ABLE ) {

		var deleteMsg = getDeletePostMsg(item.use_state);
		return(
			<li onClick={()=>{if(onClickPost) onClickPost(item)}}>
				<div>
					<div className='text_body' style={{width:bodyMaxWidth}}>
						<div className='text_body_delete'>
							<span style={{maxWidth:bodyMaxWidth}}>{deleteMsg}</span>
						</div>
						<div className='text_body_bottom' style={{maxWidth:bodyMaxWidth}}>
							<span className='category'>{postName + item.cate_name}</span>
							<span className='divider'>{ "|" }</span>
							<span>{item.insert_time}</span>
							<span className='divider'>{ "|" }</span>
							<span>{"조회:"+item.hits}</span>
							<span className='divider'>{ "|" }</span>
							<span>{"추천:"+item.likes}</span>
						</div>
					</div>
				</div>
			</li>
		)
	}

	return(
		<li onClick={()=>{if(onClickPost) onClickPost(item)}}>
			<div>
				{ haveImage && <img className="body_image" src={!!item.thumbnail?item.thumbnail:item.image01}/> }
				<div className='text_body' style={{width:bodyMaxWidth}}>
					<div className='text_body_title'>
						{ isBest &&
						<div className='best_icon'>
							<span className='best_span'>Best</span>
						</div>
						}
						<span className='title' style={{maxWidth:titleMaxWidth}}>{item.title}</span>
						{ haveReplyNum && 
							<span className='reply'>{"(" + item.reply_num + ")"}</span> 
						}
					</div>
					<div className='text_body_body' style={bodyStyle}>
						<span style={{maxWidth:bodyMaxWidth}}>{remakeBodyStrInList(item.body)}</span>
					</div>
					<div className='text_body_bottom' style={{maxWidth:bodyMaxWidth}}>
						<span className='category'>{postName + item.cate_name}</span>
						<span className='divider'>{ "|" }</span>
						<span>{item.nickname}</span>
						<span className='divider'>{ "|" }</span>
						<span>{item.insert_time}</span>
						<span className='divider'>{ "|" }</span>
						<span>{"조회:"+item.hits}</span>
						<span className='divider'>{ "|" }</span>
						<span>{"추천:"+item.likes}</span>
					</div>
				</div>
            	{ isChoice && <img className="choice_image" src={IMG_URL_CHOICE}/> }
			</div>
		</li>
	)
}
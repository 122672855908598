/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getCookieLoginData } from '../../../util/cookie_helper';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../sub/MobileWrapper';
import MainBest from './sub/MainBest';
import MobileMainPostList from './sub/MobileMainPostList';
import MobileNoticeBox from '../sub/MobileNoticeBox';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../sub/Adsense';
import MobileMainCategoryArea from './sub/MobileMainCategoryArea';
import { RootState } from '../../../redux_saga/modules';
import { PAGE_URL } from '../../../util/Page';

const MobileMain = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const mobileOpenUseEffect = useSelector((state:RootState)=>state.data.mobileOpenUseEffect);

	useEffect(()=>{
		reduxActions.data.setVisitCount(dispatch);
	},[]);
	
	useEffect(()=>{
		if( mobileOpenUseEffect ) {
			reduxActions.data.setMobileOpenUseEffect(dispatch, false);
			navigate( PAGE_URL.PERSONAL, {replace:true} );
			setTimeout(()=>{
				navigate( PAGE_URL.MAIN, {replace:true} );
				//alert("move");
			},10);
		}
		
		return(()=>{})
	},[mobileOpenUseEffect]);

	/////////////////////////////////////////////////////////////////////
	// 페이지 라우팅을 위한 구문
	useEffect(()=>{
		const l_p = new URLSearchParams(location.search);
		const page = l_p.get("route_page");
        if( !!page ) { navigate( makeMovePageStr( location.search ) ); }
		
		return(()=>{})
	},[location]);

	const makeMovePageStr = ( search : string ) => {
		var movePage = search.replace("?route_page=","/");
		var firstDivideIndex = movePage.indexOf("&");
		if( firstDivideIndex > -1 ) {
			var left = movePage.substring(0, firstDivideIndex);
			var right = movePage.substring(firstDivideIndex+1);
			movePage = left + "?" + right;
		}
		return movePage;
	}
	// End - 페이지 라우팅을 위한 구문
	/////////////////////////////////////////////////////////////////////

	return (
		<MobileWrapper
			tabType={MOBILE_TAB_TYPE.HOME}>
			<MiddleComponent {...props}/>
		</MobileWrapper>
	);
};

export default MobileMain;


function MiddleComponent( props:any ) {

	return(
		<>
			<MainBest {...props}/>
			
			<Adsense 
				{...props}
				type={AdType.MOBILE_TOP}
				marginBottom={10}
				pagePosition={AD_PAGE_POSITION.MOBILE_MAIN_MIDDLE}/>

			<MobileMainPostList {...props}/>
			<MobileMainCategoryArea {...props}/>
		</>
	)
}
import { useEffect, useState } from "react";
import { PAGE_NUMBER_DATA, makeBottomPageNumberList } from "../../../util/Page";
import { POST_CUT_YEAR, TABLE_START_YEAR_DEFAULT } from "../../../util/ConstValue";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

function MobilePageNumberBox(props:any) {
	const {totalData, year, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, year );
		setPageList(list);

        return(()=>{})
    },[totalData, year, page]);

	if( !pageList || pageList.length == 0 ) return null;

	var isLastPage = totalData?totalData.total_page==page:true;
	var isFirstPage = page==1;

	var cYear = (new Date()).getFullYear();
	
	var isHaveNextYear = year && POST_CUT_YEAR?cYear > year:false; // 다음년도가 있다면
	var isHaveBeforeYear = year && POST_CUT_YEAR?TABLE_START_YEAR_DEFAULT < year:false;	// 이전년도가 있다면

	return(
		<div className='mobile__post_page_area'>

			<div className={"before" + (isFirstPage&&!isHaveNextYear?" disable":"")} onClick={()=>{ 
				if( onClickPage ) { 
					if( isFirstPage&&isHaveNextYear ) {
						onClickPage(year+1, true); 
					}
					else {
						if( !isFirstPage ) {
							onClickPage(page-1, false); 
						}
					}
				} 
				}}>
				<div className='icon'>
					<AiOutlineLeft size={20}/>
				</div>
				<span className="page">
					{
						isFirstPage&&isHaveNextYear?(year+1)+"년":"이전"
					}
				</span>
			</div>

			<div className={"next" + (isLastPage&&!isHaveBeforeYear?" disable":"")} onClick={()=>{ 
				if( onClickPage ) { 
					if( isLastPage&&isHaveBeforeYear ) {
						onClickPage(year-1, true); 
					}
					else {
						if( !isLastPage ) {
							onClickPage(page+1, false); 
						}
					}
				} 
				}}>
				
				<span className="page">
					{
						isLastPage&&isHaveBeforeYear?(year-1)+"년":"다음"
					}
				</span>
				<div className='icon'>
					<AiOutlineRight size={20}/>
				</div>
			</div>
		</div>
	)
}
export default MobilePageNumberBox;


export const MobileTopPageNumberBox = (props:any) => {
	const {totalData, year, page, onClickPage, marginTop} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, year );
		setPageList(list);

        return(()=>{})
    },[totalData, year, page]);

	if( !pageList || pageList.length == 0 ) return null;

	var isLastPage = totalData?totalData.total_page==page:true;
	var isFirstPage = page==1;

	var cYear = (new Date()).getFullYear();
	
	var isHaveNextYear = cYear > year && POST_CUT_YEAR; // 다음년도가 있다면
	var isHaveBeforeYear = TABLE_START_YEAR_DEFAULT < year && POST_CUT_YEAR;	// 이전년도가 있다면

    var style : any = {};
    if( marginTop ) style["marginTop"] = marginTop;
	return(
		<div className='mobile__post_top_page_area'>
            <div className={"next" + (isLastPage&&!isHaveBeforeYear?" disable":"")} style={style}>
                <div className='icon'onClick={()=>{ 
                    if( onClickPage ) { 
                        if( isLastPage&&isHaveBeforeYear ) {
                            onClickPage(year-1, true); 
                        }
                        else {
                            if( !isLastPage ) {
                                onClickPage(page+1, false); 
                            }
                        }
                    } 
                    }}>
                    <AiOutlineRight size={20}/>
                </div>
            </div>
			<div className={"before" + (isFirstPage&&!isHaveNextYear?" disable":"")} style={style}>
				<div className='icon' onClick={()=>{ 
					if( onClickPage ) { 
						if( isFirstPage&&isHaveNextYear ) {
							onClickPage(year+1, true); 
						}
						else {
							if( !isFirstPage ) {
								onClickPage(page-1, false); 
							}
						}
					} 
					}}>
					<AiOutlineLeft size={20}/>
				</div>
			</div>
		</div>
	)
}
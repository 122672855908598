import { CallFunc, defaultAxiosPromise, defaultCallbackAxios, METHOD, addParam, getLoginToken, appendFormData } from "./util";
import { API_URL_ADMIN } from "./const";
import { DefaultParamAddImage, DefaultParamUpdateImage } from "./type";

const URL_AUTH = API_URL_ADMIN + "auth/";
const URL_DATA = API_URL_ADMIN + "data/";
const URL_LIST = API_URL_ADMIN + "list/";
const URL_GRAPH = API_URL_ADMIN + "graph/";


/*********************************************************************************************************
 * 
 * 관리자 계정 관련
 * 
 *********************************************************************************************************/

/* 비밀번호 확인 */
export function authCheckPw( dispatch:any, pw:string, callBack:CallFunc|null ) {
    let param : any = {pw:pw};
    
    var formData = null;
    const URL = URL_AUTH + "check_pw.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 비밀번호 수정 */
export function authPwUpdate( dispatch:any, pw:string, new_pw:string, callBack:CallFunc|null ) {
    let param : any = {pw:pw, new_pw:new_pw};
    
    var formData = null;
    const URL = URL_AUTH + "pw_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 유저 정보 수정 */
export function authUserInfoUpdate( dispatch:any, name:string, phone_number:string, email:string, callBack:CallFunc|null ) {
    let param : any = {name:name, phone_number:phone_number, email:email};
    
    var formData = null;
    const URL = URL_AUTH + "user_info_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 관리자 계정 관련
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 관리자 요청 리스트
 * 
 *********************************************************************************************************/

/* 문의 리스트 */
export function listInquiry( dispatch:any, type:number|null, read:number|null, total_search:string|null, page:number|null, callBack:CallFunc|null ) {
    let param : any = {};
    param = addParam( param, "type",            type);
    param = addParam( param, "read",            read);
    param = addParam( param, "total_search",    total_search);
    param = addParam( param, "page",            page);

    var formData = null;
    const URL = URL_LIST + "inquiry.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 공지 리스트 */
export function listNotice( dispatch:any, post_type:number|null, cate_idx:number|null, page:number|null, use_state:number|null, total_search:string|null, post_categorys:string|null, callBack:CallFunc|null ) {
    let param : any = {};
    param = addParam( param, "post_type",       post_type);
    param = addParam( param, "cate_idx",        cate_idx);
    param = addParam( param, "page",            page);
    param = addParam( param, "use_state",       use_state);
    param = addParam( param, "total_search",    total_search);
    param = addParam( param, "post_categorys",  post_categorys);

    var formData = null;
    const URL = URL_LIST + "notice.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 채택 사연 리스트 */
export function listStoryChoice( dispatch:any, params:ParamListStoryChoice, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "choice_type",     params.choice_type);
    param = addParam( param, "choice_is_send",  params.choice_is_send);
    param = addParam( param, "choice_is_show",  params.choice_is_show);
    param = addParam( param, "categorys",       params.categorys);
    
    var formData = null;
    const URL = URL_LIST + "story_choice.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 삭제글 리스트 */
export function listStoryPostDelete( dispatch:any, params:ParamListPostDelete, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "story_post_delete.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 삭제 댓글 리스트 */
export function listStoryReplyDelete( dispatch:any, params:ParamListReplyDelete, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "story_reply_delete.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 신고글 리스트 */
export function listStoryPostReport( dispatch:any, params:ParamListPostReport, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "is_delete",       params.is_delete);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "story_post_report.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 신고댓글 리스트 */
export function listStoryReplyReport( dispatch:any, params:ParamListReplyReport, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "is_delete",       params.is_delete);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "story_reply_report.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 삭제글 리스트 */
export function listCommunityPostDelete( dispatch:any, params:ParamListPostDelete, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "community_post_delete.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 삭제 댓글 리스트 */
export function listCommunityReplyDelete( dispatch:any, params:ParamListReplyDelete, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "community_reply_delete.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 신고글 리스트 */
export function listCommunityPostReport( dispatch:any, params:ParamListPostReport, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "is_delete",       params.is_delete);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "community_post_report.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 신고댓글 리스트 */
export function listCommunityReplyReport( dispatch:any, params:ParamListReplyReport, callBack:CallFunc|null ) {
    let param : any = {year:params.year};
    param = addParam( param, "page",            params.page);
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "search_type",     params.search_type);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "is_delete",       params.is_delete);
    param = addParam( param, "categorys",       params.categorys);

    var formData = null;
    const URL = URL_LIST + "community_reply_report.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 관리자 리스트 */
export function listAdminUser( dispatch:any, params:ParamListAdminUser, callBack:CallFunc|null ) {
    let param : any = {};
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "use_state",       params.use_state);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "page",            params.page);

    var formData = null;
    const URL = URL_LIST + "admin_user.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사용자 리스트 */
export function listUser( dispatch:any, params:ParamListUser, callBack:CallFunc|null ) {
    let param : any = {};
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "use_state",       params.use_state);
    param = addParam( param, "is_blacklist",    params.is_blacklist);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "page",            params.page);

    var formData = null;
    const URL = URL_LIST + "user.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 블랙 리스트 */
export function listBlackList( dispatch:any, params:ParamListBlackList, callBack:CallFunc|null ) {
    let param : any = {};
    param = addParam( param, "sort",            params.sort);
    param = addParam( param, "use_state",       params.use_state);
    param = addParam( param, "total_search",    params.total_search);
    param = addParam( param, "page",            params.page);

    var formData = null;
    const URL = URL_LIST + "blacklist.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 금지어 리스트 */
export function listTaboo( dispatch:any, total_search:string|null, page:number|null, callBack:CallFunc|null ) {
    let param : any = {};
    param = addParam( param, "total_search", total_search);
    param = addParam( param, "page", page);

    var formData = null;
    const URL = URL_LIST + "taboo.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 관리자 요청 리스트
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 데이터 요청
 * 
 *********************************************************************************************************/

/* 방문기록 요청 */
export function dataVisit( dispatch:any, type:number, start:string, end:string, remove_duplicates:number|null, callBack:CallFunc|null ) {
    let param : any = {type:type, start:start, end:end};
    param = addParam( param, "remove_duplicates", remove_duplicates);

    var formData = null;
    const URL = URL_DATA + "visit.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사용상태 변경 */
export function dataUseStateUpdate( dispatch:any, idx:number, data_type:number, use_state:number, year:number|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, data_type:data_type, use_state:use_state};
    param = addParam( param, "year", year);

    var formData = null;
    const URL = URL_DATA + "use_state_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 공지사항 추가 */
export function dataNoticeAdd( dispatch:any, params:ParamDataNoticeAdd, callBack:CallFunc|null ) {
    let param : any = {title:params.title};
    param = addParam( param, "post_type",   params.post_type);
    param = addParam( param, "cate_idx",    params.cate_idx);
    param = addParam( param, "start",       params.start);
    param = addParam( param, "end",         params.end);
    param = addParam( param, "body",        params.body);

    var formData = null;
    formData = appendFormData( formData, "thumbnail",     params.thumbnail );
    formData = appendFormData( formData, "image_file_01", params.image_file_01 );
    formData = appendFormData( formData, "image_file_02", params.image_file_02 );
    formData = appendFormData( formData, "image_file_03", params.image_file_03 );
    formData = appendFormData( formData, "image_file_04", params.image_file_04 );
    formData = appendFormData( formData, "image_file_05", params.image_file_05 );
    formData = appendFormData( formData, "image_file_06", params.image_file_06 );
    formData = appendFormData( formData, "image_file_07", params.image_file_07 );
    formData = appendFormData( formData, "image_file_08", params.image_file_08 );
    formData = appendFormData( formData, "image_file_09", params.image_file_09 );
    formData = appendFormData( formData, "image_file_10", params.image_file_10 );

    const URL = URL_DATA + "notice_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 공지사항 수정 */
export function dataNoticeUpdate( dispatch:any, params:ParamDataNoticeUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, title:params.title};
    param = addParam( param, "post_type",   params.post_type);
    param = addParam( param, "cate_idx",    params.cate_idx);
    param = addParam( param, "start",       params.start);
    param = addParam( param, "end",         params.end);
    param = addParam( param, "body",        params.body);

    param = addParam( param, "image_url_01", params.image_url_01);
    param = addParam( param, "image_url_02", params.image_url_02);
    param = addParam( param, "image_url_03", params.image_url_03);
    param = addParam( param, "image_url_04", params.image_url_04);
    param = addParam( param, "image_url_05", params.image_url_05);
    param = addParam( param, "image_url_06", params.image_url_06);
    param = addParam( param, "image_url_07", params.image_url_07);
    param = addParam( param, "image_url_08", params.image_url_08);
    param = addParam( param, "image_url_09", params.image_url_09);
    param = addParam( param, "image_url_10", params.image_url_10);

    param = addParam( param, "delete_thumbnail", params.delete_thumbnail);

    param = addParam( param, "delete_url_01", params.delete_url_01);
    param = addParam( param, "delete_url_02", params.delete_url_02);
    param = addParam( param, "delete_url_03", params.delete_url_03);
    param = addParam( param, "delete_url_04", params.delete_url_04);
    param = addParam( param, "delete_url_05", params.delete_url_05);
    param = addParam( param, "delete_url_06", params.delete_url_06);
    param = addParam( param, "delete_url_07", params.delete_url_07);
    param = addParam( param, "delete_url_08", params.delete_url_08);
    param = addParam( param, "delete_url_09", params.delete_url_09);
    param = addParam( param, "delete_url_10", params.delete_url_10);

    var formData = null;
    formData = appendFormData( formData, "thumbnail",     params.thumbnail );
    formData = appendFormData( formData, "image_file_01", params.image_file_01 );
    formData = appendFormData( formData, "image_file_02", params.image_file_02 );
    formData = appendFormData( formData, "image_file_03", params.image_file_03 );
    formData = appendFormData( formData, "image_file_04", params.image_file_04 );
    formData = appendFormData( formData, "image_file_05", params.image_file_05 );
    formData = appendFormData( formData, "image_file_06", params.image_file_06 );
    formData = appendFormData( formData, "image_file_07", params.image_file_07 );
    formData = appendFormData( formData, "image_file_08", params.image_file_08 );
    formData = appendFormData( formData, "image_file_09", params.image_file_09 );
    formData = appendFormData( formData, "image_file_10", params.image_file_10 );

    const URL = URL_DATA + "notice_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 금지어 추가 */
export function dataTabooAdd( dispatch:any, name:string, callBack:CallFunc|null ) {
    let param : any = {name:name};

    var formData = null;
    const URL = URL_DATA + "taboo_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 금지어 수정 */
export function dataTabooUpdate( dispatch:any, idx:number, name:string, callBack:CallFunc|null ) {
    let param : any = {idx:idx, name:name};

    var formData = null;
    const URL = URL_DATA + "taboo_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 블랙리스트 추가 */
export function dataBlackListAdd( dispatch:any, user_idx:number, infinite:number, block_day:number|null, block_reason:string|null, callBack:CallFunc|null ) {
    let param : any = {user_idx:user_idx, infinite:infinite};
    param = addParam( param, "block_day",       block_day);
    param = addParam( param, "block_reason",    block_reason);

    var formData = null;
    const URL = URL_DATA + "blacklist_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 블랙리스트 취소 */
export function dataBlackListCancel( dispatch:any, user_idx:number, cancel_reason:string|null, callBack:CallFunc|null ) {
    let param : any = {user_idx:user_idx};
    param = addParam( param, "cancel_reason",    cancel_reason);

    var formData = null;
    const URL = URL_DATA + "blacklist_cancel.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 문의 답변 하기 */
export function dataInquiryReplyAdd( dispatch:any, inqury_idx:number, body:string, callBack:CallFunc|null ) {
    let param : any = {inqury_idx:inqury_idx, body:body};

    var formData = null;
    const URL = URL_DATA + "inquiry_reply_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 문의 데이터 요청 */
export function dataInquiry( dispatch:any, inqury_idx:number, callBack:CallFunc|null ) {
    let param : any = {inqury_idx:inqury_idx};

    var formData = null;
    const URL = URL_DATA + "inquiry.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 관리자 추가 */
export function dataAdminAdd( dispatch:any, params:ParamDataAdminAdd, callBack:CallFunc|null ) {
    let param : any = {id:params.id, pw:params.pw, name:params.name, access:params.access};
    param = addParam( param, "number",      params.number);
    param = addParam( param, "email",       params.email);
    param = addParam( param, "access_type", params.access_type);

    var formData = null;
    const URL = URL_DATA + "admin_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 관리자 수정 */
export function dataAdminUpdate( dispatch:any, params:ParamDataAdminUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, name:params.name, access:params.access};
    param = addParam( param, "pw",          params.pw);
    param = addParam( param, "number",      params.number);
    param = addParam( param, "email",       params.email);
    param = addParam( param, "access_type", params.access_type);

    var formData = null;
    const URL = URL_DATA + "admin_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 관리자 로그인 코드 번호 수정 */
export function dataLoginCodeUpdate( dispatch:any, code:number, callBack:CallFunc|null ) {
    let param : any = {code:code};

    var formData = null;
    const URL = URL_DATA + "login_code_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 베스트글 도달 좋아요 개수 기준 수정 */
export function dataStandardCountBestPostUpdate( dispatch:any, count:number, callBack:CallFunc|null ) {
    let param : any = {count:count};

    var formData = null;
    const URL = URL_DATA + "standard_count_best_post_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 베스트댓글 도달 좋아요 개수 기준 수정 */
export function dataStandardCountBestReplyUpdate( dispatch:any, count:number, callBack:CallFunc|null ) {
    let param : any = {count:count};

    var formData = null;
    const URL = URL_DATA + "standard_count_best_reply_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 숨김처리 도달 신고 개수 기준 수정 */
export function dataStandardCountHideReportUpdate( dispatch:any, count:number, callBack:CallFunc|null ) {
    let param : any = {count:count};

    var formData = null;
    const URL = URL_DATA + "standard_count_hide_report_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 회원등급 기준 개수 수정 */
export function dataStandardUserLevelUpdate( dispatch:any, params:ParamDataStandardUserLevelUpdate, callBack:CallFunc|null ) {
    let param : any = {
        level:params.level,
        best:params.best,
        post:params.post,
        reply:params.reply,
        login:params.login
    };

    var formData = null;
    const URL = URL_DATA + "standard_user_level_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 시스템 설정 데이터 요청 */
export function dataSystemSettingData( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL = URL_DATA + "system_setting_data.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 유저 블랙리스트 데이터 요청 */
export function dataUserBlacklistData( dispatch:any, idx:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx};

    var formData = null;
    const URL = URL_DATA + "user_blacklist_data.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 관리자 메일 수정 */
export function dataUpdateAdminEmail( dispatch:any, email:string, callBack:CallFunc|null ) {
    let param : any = {email:email};

    var formData = null;
    const URL = URL_DATA + "update_admin_email.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 데이터 요청
 *********************************************************************************************************/


/*********************************************************************************************************
 * 
 * 사연 데이터 요청
 * 
 *********************************************************************************************************/

/* 사연 삭제 */
export function dataStoryPostDelete( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "story_post_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 댓글 삭제 */
export function dataStoryReplyDelete( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "story_reply_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 삭제 취소 */
export function dataStoryPostDeleteCancel( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "story_post_delete_cancel.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 댓글 삭제 취소 */
export function dataStoryReplyDeleteCancel( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "story_reply_delete_cancel.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 영구삭제 */
export function dataStoryPostCompletelyDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "story_post_completely_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 댓글 영구 삭제 */
export function dataStoryReplyCompletelyDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "story_reply_completely_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 카테고리 추가 */
export function dataStoryCategoryAdd( dispatch:any, name:string, callBack:CallFunc|null ) {
    let param : any = {name:name};

    var formData = null;
    const URL = URL_DATA + "story_category_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 카테고리 수정 */
export function dataStoryCategoryUpdate( dispatch:any, idx:number, name:string, callBack:CallFunc|null ) {
    let param : any = {idx:idx, name:name};

    var formData = null;
    const URL = URL_DATA + "story_category_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 채택 */
export function dataStoryChoiceAdd( dispatch:any, idx:number, year:number, choice_type:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year, choice_type:choice_type};

    var formData = null;
    const URL = URL_DATA + "story_choice_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 채택 사연 업데이트 */
export function dataStoryChoiceUpdate( dispatch:any, params:ParamDataStoryChoiceUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, year:params.year};
    param = addParam( param, "choice_type",         params.choice_type);
    param = addParam( param, "choice_price",        params.choice_price);
    param = addParam( param, "choice_is_send",      params.choice_is_send);
    param = addParam( param, "choice_send_date",    params.choice_send_date);
    param = addParam( param, "choice_video",        params.choice_video);
    param = addParam( param, "choice_show",         params.choice_show);

    var formData = null;
    const URL = URL_DATA + "story_choice_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 채택 사연 취소 */
export function dataStoryChoiceCancel( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "story_choice_cancel.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 사연 데이터 요청
 *********************************************************************************************************/


/*********************************************************************************************************
 * 
 * 커뮤니티 데이터 요청
 * 
 *********************************************************************************************************/

/* 커뮤니티 삭제 */
export function dataCommunityPostDelete( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "community_post_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 댓글 삭제 */
export function dataCommunityReplyDelete( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "community_reply_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 삭제 취소 */
export function dataCommunityPostDeleteCancel( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "community_post_delete_cancel.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 댓글 삭제 취소 */
export function dataCommunityReplyDeleteCancel( dispatch:any, idx:number, year:number, note:string|null, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};
    param = addParam( param, "note", note);

    var formData = null;
    const URL = URL_DATA + "community_reply_delete_cancel.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 영구삭제 */
export function dataCommunityPostCompletelyDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "community_post_completely_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 댓글 영구 삭제 */
export function dataCommunityReplyCompletelyDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "community_reply_completely_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 카테고리 추가 */
export function dataCommunityCategoryAdd( dispatch:any, params:ParamDataCommunityCategoryAdd, callBack:CallFunc|null ) {
    let param : any = {name:params.name};
    param = addParam( param, "channel", params.channel);

    var formData = null;
    formData = appendFormData( formData, "banner", params.banner );
    formData = appendFormData( formData, "mob_banner", params.mob_banner );

    const URL = URL_DATA + "community_category_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 카테고리 수정 */
export function dataCommunityCategoryUpdate( dispatch:any, params:ParamDataCommunityCategoryUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, name:params.name};
    param = addParam( param, "channel", params.channel);
    param = addParam( param, "delete_banner", params.delete_banner);
    param = addParam( param, "delete_mob_banner", params.delete_mob_banner);

    var formData = null;
    formData = appendFormData( formData, "banner", params.banner );
    formData = appendFormData( formData, "mob_banner", params.mob_banner );

    const URL = URL_DATA + "community_category_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}


/**
 * End - 커뮤니티 데이터 요청
 *********************************************************************************************************/


// --------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------------------------------------------------------- //
//                                                                                                                 //
//                                                ↓↓↓↓ 파라미터 ↓↓↓↓                                                //
//                                                                                                                 //
// --------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------------------------------------------------------- //



/*********************************************************************************************************
 * 리스트 파라미터
 *********************************************************************************************************/

interface DefaultParamList {
    page:number|null,
    sort:number|null,
    search_type:number|null,
    total_search:string|null,
    categorys:string|null
}

interface DefaultParamUserList {
    sort:number|null,
    use_state:number|null,
    total_search:string|null,
    page:number|null
}

export interface ParamListStoryChoice extends DefaultParamList {
    year:number,
    choice_type:number|null,
    choice_is_send:number|null,
    choice_is_show:number|null
}

export interface ParamListPostDelete extends DefaultParamList {
    year:number
}

export interface ParamListReplyDelete extends DefaultParamList {
    year:number
}

export interface ParamListPostReport extends DefaultParamList {
    year:number,
    is_delete:number|null
}

export interface ParamListReplyReport extends DefaultParamList {
    year:number,
    is_delete:number|null
}

export interface ParamListAdminUser extends DefaultParamUserList {}

export interface ParamListUser extends DefaultParamUserList {
    is_blacklist:number|null
}

export interface ParamListBlackList extends DefaultParamUserList {}

/**
 * End - 리스트 파라미터
 *********************************************************************************************************/


/*********************************************************************************************************
 * 데이터 파라미터
 *********************************************************************************************************/

export interface ParamDataNoticeAdd extends DefaultParamAddImage {
    post_type:number|null,
    cate_idx:number|null,
    start:number|null,
    end:number|null,
    title:string,
    body:string|null
}

export interface ParamDataNoticeUpdate extends DefaultParamUpdateImage {
    idx:number,
    post_type:number|null,
    cate_idx:number|null,
    start:number|null,
    end:number|null,
    title:string,
    body:string|null
}

export interface ParamDataAdminAdd {
    id:string,
    pw:string,
    name:string,
    number:string|null,
    email:string|null,
    access:number,
    access_type:string|null
}

export interface ParamDataAdminUpdate {
    idx:number,
    pw:string|null,
    name:string,
    number:string|null,
    email:string|null,
    access:number,
    access_type:string|null
}

export interface ParamDataStandardUserLevelUpdate {
    level:number,
    best:number,
    post:number,
    reply:number,
    login:number,
}

export interface ParamDataStoryChoiceUpdate {
    idx:number,
    year:number,
    choice_type:number|null,
    choice_price:number|null,
    choice_is_send:number|null,
    choice_send_date:number|null,
    choice_video:string|null,
    choice_show:number|null
}

export interface ParamDataCommunityCategoryAdd {
    name:string, 
    channel:string|null, 
    banner:File|null, 
    mob_banner:File|null
}

export interface ParamDataCommunityCategoryUpdate extends ParamDataCommunityCategoryAdd {
    idx:number, 
    delete_banner:string|null, 
    delete_mob_banner:string|null
}

/**
 * End - 데이터 파라미터
 *********************************************************************************************************/
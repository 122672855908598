/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { getDeletePostMsg, movePage, remakeBodyStrInList } from '../../../util/Global';
import { CANT_READ_POST_ON_DELETE, IMG_URL_CHOICE, POST_CUT_YEAR } from '../../../util/ConstValue';
import { PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_POST_PARAMS, PAGE_URL, getStartPage, makeBottomPageNumberList, makeUrl } from '../../../util/Page';
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux_saga/modules';


function MainPostListComponent( props:any ) {
	const location = useLocation();

	const [itemList, setItemList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);
	const [tabType, setTabType] = useState(API_TYPES.POST_TYPE.ALL);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);

    useEffect(()=>{

		const l_p = new URLSearchParams(location.search);
		const y = l_p.get("year");
		const plt = l_p.get("post_list_type");

		var newYear = (new Date()).getFullYear();
		var newTabType = API_TYPES.POST_TYPE.ALL;
		
		setYear(newYear);
		setTabType(newTabType);

		if( y ) 	{ try{ newYear				= parseInt(y); 		}catch(e){ console.log("e",e);} }
		if( plt ) 	{ 
			try{ 
				var newPostListType = parseInt(plt);	
				if( newPostListType == API_TYPES.POST_LIST_TYPE.ALL )		 			{ newTabType = API_TYPES.POST_TYPE.ALL; }
				else if( newPostListType == API_TYPES.POST_LIST_TYPE.ALL_COMMUNITY ) 	{ newTabType = API_TYPES.POST_TYPE.COMMUNITY; }
				else if( newPostListType == API_TYPES.POST_LIST_TYPE.ALL_STORY ) 		{ newTabType = API_TYPES.POST_TYPE.STORY; }
			}catch(e){ console.log("e",e);} 
		}

		setYear(newYear);
		setTabType(newTabType);

        return(()=>{})
    },[location]);

	return(
		<>
			<div className='home__post_area' id="home__post_area">
				<TabComponent {...props} currentType={tabType} setCurrentType={(type:number)=>{
					setTabType(type);
					setYear((new Date()).getFullYear());
					setPage(1);
				}}/>
				<PostComponent {...props} 
					currentType={tabType}
					itemList={itemList}
					setItemList={setItemList}
					totalData={totalData}
					setTotalData={setTotalData}
					year={year}
					page={page}
				/>
			</div>
			<PageComponent {...props}
				totalData={totalData}
				year={year}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA)=>{
					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						setPage( item.page );
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						setPage( getStartPage(page) - 10 );
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						setPage( getStartPage(page) + 10 );
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR ) { 
						setYear( item.page );
						setPage(1);
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR ) { 
						setYear( item.page );
						setPage(1);
					 }
					 const ele : any = document.getElementById("home__post_area");
					const elePosition = window.scrollY + ele.getBoundingClientRect().top;
					window.scrollTo(0,elePosition-20);
				}}
			/>
		</>
	)
}
export default MainPostListComponent;

function PageComponent(props:any) {
	const {totalData, year, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, year );
		setPageList(list);

        return(()=>{})
    },[totalData, year, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='home__post_page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 							{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 						{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 						{ numberStr = "다음"; 			className="next"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && POST_CUT_YEAR )	{ numberStr = item.page+"년"; 	className="before_year";  yearStyle={width:100}}
				else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && POST_CUT_YEAR )	{ numberStr = item.page+"년"; 	className="next_year";  	yearStyle={width:100}}

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						{ item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && item.page != -1 && POST_CUT_YEAR &&
							<span className="dot">{"·"}</span>
						}
						<span className="page" onClick={()=>{ if( onClickPage ) { onClickPage(item); } }}>
							{item.page != -1?numberStr:""}
						</span>

						{ item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && item.page != -1 && POST_CUT_YEAR &&
							<span className="dot">{"·"}</span>
						}
					</div>
				)
			})}
		</div>
	)
}

function TabComponent( props:any ) {
	return(
		<div className='tab_box'>
			<TabItem {...props} name="전체" tabType={API_TYPES.POST_TYPE.ALL}/>
			<TabItem {...props} name="사연" tabType={API_TYPES.POST_TYPE.STORY}/>
			<TabItem {...props} name="커뮤니티" tabType={API_TYPES.POST_TYPE.COMMUNITY}/>
		</div>
	)
}

function TabItem( props:any ) {
	const {name, currentType, setCurrentType, tabType} = props;

	const clickedTab = () => {
		if( currentType != tabType ) {
			setCurrentType(tabType);
		}
	}

	var tabClassName = "tab_item";
	if( currentType == tabType ) {
		tabClassName = tabClassName + " select";
	}
	return(
		<div className={tabClassName} onClick={clickedTab}>
			<span>{name}</span>
		</div>
	)
}

function PostComponent( props:any ) {
	const { currentType, itemList, setItemList, setTotalData, year, page } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);

	const dispatch = useDispatch();
	const navigate = useNavigate();

    useEffect(()=>{
		reqList();

        return(()=>{})
    },[currentType, year, page]);

	const reqList = () => {
		var search_type = null;
		var total_search = null;

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				setItemList( resData.list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		}

		if( currentType == API_TYPES.POST_TYPE.ALL ) {
			
			apiAxios.global.listAllPost( dispatch, year, page, search_type, total_search, callback);
		}
		else if( currentType == API_TYPES.POST_TYPE.STORY ) {
			apiAxios.global.listStoryAllPost( dispatch, year, page, search_type, total_search, callback);
		}
		else if( currentType == API_TYPES.POST_TYPE.COMMUNITY ) {
			apiAxios.global.listCommunityAllPost( dispatch, year, page, search_type, total_search, callback);
		}
	}

	const clickedPost = ( item:any, e:any ) => {
		var postType = currentType==API_TYPES.POST_TYPE.ALL?item.post_type:currentType;
		var url = "";
		if( postType == API_TYPES.POST_TYPE.STORY ) 		url = PAGE_URL.STORY_POST;
		else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) url = PAGE_URL.COMMUNITY_POST;

		// 사용가능 상태가 아니고 관리자 로그인이 아니라면 접근 불가
		if( item.use_state != API_TYPES.POST_USE_STATE.ABLE && (!loginData||loginData.is_admin!=API_TYPES.BOOLEAN.TRUE) ) {
			alert(CANT_READ_POST_ON_DELETE);
			return;
		}

		
        const params : PAGE_POST_PARAMS = {
            idx:item.idx,
            year:item.year,
            post_list_type:currentType,
            post_type:postType,
            category:item.cate_idx,
            search:null,
            searchType:null
        }
        var fullUrl = makeUrl(url, params);
        movePage(navigate, e, fullUrl);
	}

	return(
		<div className="post_box">
			{ !!itemList && itemList.length > 0 &&
				<ul>
					{itemList.map((item:any, index:number)=>{
						return(
							<PostItem {...props} key={index} item={item} onClickPost={clickedPost}/>
						)
					})}
				</ul>
			}
			{ (!itemList || itemList.length == 0) &&
				<div className='empty'>{year + "년 게시글이 없습니다"}</div>
			}
		</div>
	)
}

function PostItem( props:any ) {
	const {currentType, item, onClickPost} = props;

	const haveImage = !!item.image01;
	const isBest = item.best == API_TYPES.BOOLEAN.TRUE;
    const isChoice = item.choice_show == API_TYPES.BOOLEAN.TRUE;

    const haveReplyNum = item.reply_num && item.reply_num > 0;
	const titleMaxWidth = 940 - (haveImage?150:0) - (isBest?60:0) - (haveReplyNum?60:0) - (isChoice?120:0);
	const bodyMaxWidth = 940 - (haveImage?150:0) - (isChoice?120:0);

	var postName = "";
	var postType = currentType==API_TYPES.POST_TYPE.ALL?item.post_type:currentType;

	if( currentType==API_TYPES.POST_TYPE.ALL && postType==API_TYPES.POST_TYPE.STORY ) { postName = "사연 · "; }
	else if( currentType==API_TYPES.POST_TYPE.ALL && postType==API_TYPES.POST_TYPE.COMMUNITY ) { postName = "커뮤니티 · "; }

	const bodyStyle = haveImage||isChoice?{maxWidth:bodyMaxWidth, height:40}:{maxWidth:bodyMaxWidth}; // 고정 바디 값이 있는가?


	if( item.use_state!=API_TYPES.POST_USE_STATE.ABLE ) {

		var deleteMsg = getDeletePostMsg(item.use_state);
		return(
			<li onClick={(e:any)=>{if(onClickPost) onClickPost(item, e)}}>
				<div>
					<div className='text_body' style={{width:bodyMaxWidth}}>
						<div className='text_body_delete'>
							<span style={{maxWidth:bodyMaxWidth}}>{deleteMsg}</span>
						</div>
						<div className='text_body_bottom' style={{maxWidth:bodyMaxWidth}}>
							<span className='category'>{postName + item.cate_name}</span>
							<span className='divider'>{ "|" }</span>
							<span>{item.insert_time}</span>
							<span className='divider'>{ "|" }</span>
							<span>{"조회:"+item.hits}</span>
							<span className='divider'>{ "|" }</span>
							<span>{"추천:"+item.likes}</span>
						</div>
					</div>
				</div>
			</li>
		)
	}

	return(
		<li onClick={(e:any)=>{if(onClickPost) onClickPost(item, e)}}>
			<div>
				{ haveImage && <img className="body_image" src={!!item.thumbnail?item.thumbnail:item.image01}/> }
				<div className='text_body' style={{width:bodyMaxWidth}}>
					<div className='text_body_title'>
						{ isBest &&
						<div className='best_icon'>
							<span className='best_span'>Best</span>
						</div>
						}
						<span className='title' style={{maxWidth:titleMaxWidth}}>{item.title}</span>
						{ haveReplyNum && 
							<span className='reply'>{"(" + item.reply_num + ")"}</span> 
						}
					</div>
					<div className='text_body_body' style={bodyStyle}>
						<span style={{maxWidth:bodyMaxWidth}}>{remakeBodyStrInList(item.body)}</span>
					</div>
					<div className='text_body_bottom' style={{maxWidth:bodyMaxWidth}}>
						<span className='category'>{postName + item.cate_name}</span>
						<span className='divider'>{ "|" }</span>
						<span>{item.nickname}</span>
						<span className='divider'>{ "|" }</span>
						<span>{item.insert_time}</span>
						<span className='divider'>{ "|" }</span>
						<span>{"조회:"+item.hits}</span>
						<span className='divider'>{ "|" }</span>
						<span>{"추천:"+item.likes}</span>
					</div>
				</div>
            	{ isChoice && <img className="choice_image" src={IMG_URL_CHOICE}/> }
			</div>
		</li>
	)
}
/****************************************************************************************
 * 
 *  화면 우측 부분
 *  
 *  로그인 , 유저 정보 , 글쓰기 버튼 , 카테고리 , 공지사항 , 광고
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { POST_TYPE } from '../../apiAxios/v1/type';
import { PAGE_NOTICE_HOME_PARAMS, PAGE_NOTICE_POST_PARAMS, PAGE_POST_HOME_PARAMS, PAGE_URL, PAGE_WRITE_POST, makeUrl } from '../../util/Page';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import {Oval} from 'react-loader-spinner';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { getCookieLoginData, removeCookieLoginData, setCookieLoginData } from '../../util/cookie_helper';
import { RootState } from '../../redux_saga/modules';
import LevelIcon from './LevelIcon';
import { TAB_TYPE } from './TopTab';
import Adsense, { AD_PAGE_POSITION, AdType } from './Adsense';
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, checkAbleManageAdminPage, makeAdminManageData } from '../../util/AdminAccessHelper';

function RightArea( props : any ) {
    const{ categoryData, hideNotice, tabType, hideWriteBtn } = props;
	const navigate = useNavigate();
    const loginData = useSelector((state:RootState)=>state.data.loginData);
    const userData = useSelector((state:RootState)=>state.data.userData);
    const visitCountData = useSelector((state:RootState)=>state.data.visitCountData);

    useEffect(()=>{
        return(()=>{})
    },[]);

    const clickedWritePost = () => {
        var url = "";
        var categoryIdx = categoryData&&categoryData.currentCategory>0?categoryData.currentCategory:null;
        var post_type = API_TYPES.POST_TYPE.ALL;
        if( tabType == TAB_TYPE.COMMUNITY ) {
            post_type = API_TYPES.POST_TYPE.COMMUNITY;
        }
        else if( tabType == TAB_TYPE.STORY ) {
            post_type = API_TYPES.POST_TYPE.STORY;
        }
        var params : PAGE_WRITE_POST = {
            category:categoryIdx,
            year:null,
            idx:null,
            post_type:post_type
        }

        if( userData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
            if( params.post_type == API_TYPES.POST_TYPE.ALL ) params.post_type = null;

            if( checkAbleManageAdminPage(loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.NOTICE) ).accessType == ADMIN_ACCESS_TYPE.CANT ) {
                alert("공지사항 쓰기 권한이 없습니다");
                return;
            }
            url = makeUrl(PAGE_URL.ADMIN_NOTICE_WIRTE, params);
        }
        else {
            url = makeUrl(PAGE_URL.WRITE_POST, params);
        }
        navigate( url );
    }

    
	const makePriceNumber = ( x : string ) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } 

    return (
        <div className='right_area'>
            { !!loginData && <>
                <UserBox {...props}/>
                
                { hideWriteBtn != true && 
                    <div className='write_btn default_btn' onClick={clickedWritePost}>
                        <span>{userData&&userData.is_admin == API_TYPES.BOOLEAN.TRUE?"공지 쓰기":"글쓰기"}</span>
                    </div>
                }
            </>}
            { !loginData && <LoginBox {...props}/>}
            { !!categoryData && <CategoryComponent {...props}/>}
            { hideNotice != true && <NoticeComponent {...props}/>}
            
            { userData && userData.is_admin == API_TYPES.BOOLEAN.TRUE && <>
                <div className='visit'><span>오늘 방문자 </span><span className='blue'>{makePriceNumber(visitCountData.user+"")}</span><span> 명</span></div>
                <div className='visit'><span>[ 오늘 총 방문 </span><span className='red'>{makePriceNumber(visitCountData.total+"")}</span><span> ]</span></div>
                </>}
            <BottomAd {...props}/>
        </div>
    );
}

export default RightArea;


/****************************************************************************************************
 * 유저 정보 박스 컴포넌트
 ****************************************************************************************************/
function UserBox( props : any ) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
    const loginData = useSelector((state:RootState)=>state.data.loginData);
    const userData = useSelector((state:RootState)=>state.data.userData);

    useEffect(()=>{
        if( loginData ) { reqUserData(); }
        else { reduxActions.data.setUserData(dispatch, null); }

        return(()=>{})
    },[loginData]);

    const reqUserData = () => {
        apiAxios.global.authUserData(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                reduxActions.data.setUserData(dispatch, resData);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData ) {
                    if( errorData.result_code == API_TYPES.RESULT_CODE.ERROR_TOKEN ) { // 아이디를 찾을 수 없음
                    }
				}
                alert("유저 정보를 가져올 수 없습니다.\n로그아웃 됩니다");
                clickLogout();
			}
		})
    }

    const clickLogout = () => {
        reduxActions.data.setLoginData(dispatch, null);
        reduxActions.data.setUserData(dispatch, null);
        //removeCookieLoginData();

        var d = getCookieLoginData();
        console.log("clickLogout",d);
    }

    const movePage = ( url:string, params:string|null = null ) => {
        navigate( url + (!!params?params:"") );
    }

    if( !userData ) {
        return (
            <div className='user_box'>
                <div className='loader'>
                    <Oval 
                        color="#00f" 
                        height={20} 
                        width={20}
                    />
                </div>
            </div>
        );
    }

    var countData = { post:0, best:0, choice:0 }
    var name = "";

    if( userData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
        name = userData.name;
    }
    else {
        countData.post = parseInt(userData.counts.story) + parseInt(userData.counts.community);
        countData.best = parseInt(userData.counts.story_best) + parseInt(userData.counts.community_best);
        countData.choice = userData.counts.story_choice;

        name = !!userData.nickname?userData.nickname:userData.name;
    }

    return(
        <div className='user_box'>
            <div className='info_area'>
                <LevelIcon 
                    is_admin={userData.is_admin}
                    level={userData.level}/>
                <div className='info'>
                    <div className='name'><span>{name + "님"}</span></div>
                    <div className='update_info'>
                        <span onClick={()=>{ 
                            if( userData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
                                movePage(PAGE_URL.ADMIN_SETTING); 
                            }
                            else {
                                movePage(PAGE_URL.USER_INFO); 
                            }
                        }}>정보수정</span>
                        
                        { userData.is_admin != API_TYPES.BOOLEAN.TRUE && <>
                            <span className='dot'>·</span>
                            <span style={{marginLeft:5}} onClick={()=>{ 
                                movePage(PAGE_URL.MY_BLOCK); 
                            }}>차단</span>
                        </>}
                    </div>
                </div>
            </div>
            <div className='logout_btn default_btn' onClick={clickLogout}>
                <span>로그아웃</span>
            </div>

            {  userData.is_admin == API_TYPES.BOOLEAN.FALSE && <>
                <div className='divider'></div>
                <div className='post_info_area'>
                    <div>
                        <span className='title'>내가 쓴글</span>
                        <span className='count'>{countData.post+"개"}</span>
                    </div>
                    <div>
                        <span className='title'>베스트</span>
                        <span className='count'>{countData.best+"개"}</span>
                    </div>
                    <div>
                        <span className='title'>채택사연</span>
                        <span className='count'>{countData.choice+"개"}</span>
                    </div>
                </div>
                
                <div className='bottom_area'>
                    <div className='my_post'>
                        <span onClick={()=>{ movePage(PAGE_URL.MY_POST); }}>내가 쓴글 보기</span>
                    </div>
                    <div className='bottom_right_area'>
                        <span className='inquiry' onClick={()=>{ movePage(PAGE_URL.MY_INQUIRY); }}>나의 문의 내역</span>
                    </div>
                </div>
            </>}
        </div>
    )
}
/**
 * End - 유저 정보 박스 컴포넌트
 ****************************************************************************************************/


/****************************************************************************************************
 * 로그인 박스 컴포넌트
 ****************************************************************************************************/
function LoginBox( props : any ) {
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);
    const fcmToken : string|null = useSelector((state:RootState)=>state.data.fcmToken);

    const [idValue, setIdValue] = useState("");
    const [pwValue, setPwValue] = useState("");
    const [isRequest, setIsRequest] = useState(false);

    const clickedLogin = () => {
        if( isRequest ) return;
        if( checkEmptyInsertData() ) return;

        reqLogin(idValue, pwValue, null);
    }

    const checkEmptyInsertData = () => {
        if( !idValue ) { alert("아이디를 입력하세요"); return true; }
        else if( !pwValue ) { alert("비밀번호를 입력하세요"); return true; }
        return false;
    }
    
    const handleKeyUp = (e:any) => {
        if( e.keyCode == 13){ // 키보드 Enter 클릭
            clickedLogin();
        }
      }

    const reqLogin = ( id:string, pw:string, code:string|null ) => {
        setIsRequest(true);
		apiAxios.global.authLogin(dispatch, id, pw, code, applicationOS, fcmToken, (response:AxiosResponse|null, error:AxiosError|null) => {
            setIsRequest(false);

			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const newData : any = {...resData};
				newData["pw"] = pw;
				newData["code"] = code;

                setCookieLoginData(newData);
                reduxActions.data.setLoginData(dispatch, newData);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData ) {
                    if( errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND && 
                        ( errorData.data == 1 || errorData.data == 2 || errorData.data == 4 ) ) { // 아이디를 찾을 수 없음
                        alert("아이디 또는 비밀번호가 잘못 되었습니다");
                    }
                    else  if( errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND && errorData.data == 3 ) { // 로그인 코드가 맞지 않을 때
                        // 코드 입력인 경우라면
                        if( !!code ) {
                            alert("관리자 로그인 코드가 잘못 되었습니다");
                        }
                        else {
                            var title = "관리자 로그인 입니다. 코드번호를 입력하세요";
                            var returnValue = window.prompt(title, "");
                            if (returnValue) {
                                reqLogin( id, pw, returnValue);
                            }
                        }
                    }
                    else if( errorData.result_code == API_TYPES.RESULT_CODE.BLACKLIST ) {

                    }
				}
			}
		})
    }

    const clickedSignup = () => {
        navigate( PAGE_URL.SIGNUP );
    }

    const clickedFindId = () => {
        navigate( PAGE_URL.FIND_ID );
    }

    const clickedFindPw = () => {
        navigate( PAGE_URL.FIND_PW );
    }

    return(
        <div className='login_box'>
            <input className='input_id' name='id' type='text' placeholder="아이디" disabled={isRequest} autoComplete="off" value={idValue} onChange={(e)=> {setIdValue(e.target.value)}} onKeyUp={handleKeyUp}/>
            <input className='input_pw' name='password' type='password' placeholder="비밀번호" disabled={isRequest} autoComplete="off" value={pwValue} onChange={(e)=> {setPwValue(e.target.value)}} onKeyUp={handleKeyUp}/>
            <div className='login_btn default_btn' onClick={clickedLogin}>
                { isRequest == true &&
                    <div className='loader'>
                        <Oval 
                            color="#fff" 
                            height={20} 
                            width={20}
                        />
                   </div>
                }
                { isRequest == false &&
                    <span>로그인</span>
                }
            </div>
            <div className='bottom_area'>
                <div className='sign_up_btn' onClick={clickedSignup}>
                    <span>회원가입</span>
                </div>
                <div className='bottom_right_area'>
                    <span className='find_id' onClick={clickedFindId}>아이디</span>
                    <span className='find_divide'>/</span>
                    <span className='find_pw' onClick={clickedFindPw}>비번찾기</span>
                </div>
            </div>
        </div>
    )
}
/**
 * End - 로그인 박스 컴포넌트
 ****************************************************************************************************/


/****************************************************************************************************
 * 카테고리 컴포넌트
 ****************************************************************************************************/

/**
 * 오른쪽 카테고리 데이터
 */
export interface RIGHT_CATEGORY_DATA {
    type:number,
    currentCategory:number,
    list:any
}

function CategoryComponent( props : any ) {
	const navigate = useNavigate();
    const {categoryData} = props;

    if( !categoryData ) return null;
    if( !categoryData.list || categoryData.list.length == 0 ) return null;

    const clickedCategory = (item:any) => {
        var url = "";
        if( categoryData.type == API_TYPES.POST_TYPE.STORY ) {
            url = PAGE_URL.STORY;
        }
        else if( categoryData.type == API_TYPES.POST_TYPE.COMMUNITY ){
            url = PAGE_URL.COMMUNITY;
        }

        const params : PAGE_POST_HOME_PARAMS = {
            category:item.idx,
            year:(new Date()).getFullYear(),
            page:1
        }
        var url = makeUrl(url, params);
        navigate(url);
    }

    return(
        <div className='list_box'>
            <div className='title_box'>
                <span>카테고리</span>
            </div>
            <div className='list_area'>
                <ul>
                    { categoryData.list.map((item:any, index:number)=>{

                        const isFocus = categoryData.currentCategory == item.idx;
                        return (
                            <li key={index} className={isFocus?"focus":""} onClick={()=>{
                                if( isFocus == false ) clickedCategory(item);
                            }}>{item.name}</li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
/**
 * End - 카테고리 컴포넌트
 ****************************************************************************************************/


/****************************************************************************************************
 * 공지사항 컴포넌트
 ****************************************************************************************************/
function NoticeComponent( props:any ) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
    const [noticeList, setNoticeList] = useState<any>(null);
    const location = useLocation();

    useEffect(()=>{
        reqNoticeList();

        return(()=>{})
    },[location]);
    

	const reqNoticeList = () => {

		apiAxios.global.listNotice(dispatch, API_TYPES.POST_TYPE.ALL, null, null, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				setNoticeList(resData.list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
	}

    if( !noticeList )   return null;

    const clickedNotice = ( item:any ) => {
        var fullUrl = "";

        if( item ) {
            const params : PAGE_NOTICE_POST_PARAMS = {
                idx:item.idx,
                post_type:API_TYPES.POST_TYPE.ALL, 
                category:0
            }
            fullUrl = makeUrl(PAGE_URL.NOTICE_POST, params);
        }
        else {
            const params : PAGE_NOTICE_HOME_PARAMS = {
                page:1
            }
            fullUrl = makeUrl(PAGE_URL.NOTICE, params);
        }
        navigate(fullUrl);
    }

    return(
        <div className='list_box'>
            <div className='title_box'>
                <span>공지사항</span>
                <div className='right_area'>
                    <span onClick={()=>{
                        clickedNotice(null);
                    }}>전체보기</span>
                </div>
            </div>
            <div className='list_area'>
                <ul>
                    { noticeList.map((item:any, index:number)=>{
                        return (
                            <li key={index} onClick={()=>{
                                clickedNotice(item);
                            }}>{item.title}</li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
/**
 * End - 공지사항 컴포넌트
 ****************************************************************************************************/



function BottomAd( props : any ) {

    useEffect(()=>{

        return(()=>{})
    },[]);

    return (
        
        <Adsense 
            {...props}
            className='right_bottom_ad'
            type={AdType.WEB_RIGHT_BOTTOM}
            pagePosition={AD_PAGE_POSITION.RIGHT_BOTTOM}
            />
    );
}
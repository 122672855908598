import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import DATA_STATE from '../domain/data';
import { APP_INTERFACE_SEND_TYPE, APP_INTERFACE_LOGIN_DATA, APP_INTERFACE_LOGOUT_DATA, sendDataToApp } from '../../util/AppInterface';
import { AxiosResponse } from 'axios';
import apiAxios from '../../apiAxios';
import { removeCookieLoginData } from '../../util/cookie_helper';

const INIT                      = 'data::init' as const;
const LOGIN_DATA                = 'data::loginData' as const;
const USER_DATA                 = 'data::userData' as const;
const STORY_CATEGORY_LIST       = 'data::storyCategoryList' as const;
const COMMUNITY_CATEGORY_LIST   = 'data::communityCategoryList' as const;
const ADMIN_EMAIL               = 'data::adminEmail' as const;
const APPLICATION_OS            = 'data::applicationOS' as const;
const FCM_TOKEN                 = 'data::fcmToken' as const;
const MOBILE_OPEN_USE_EFFECT    = 'data::mobileOpenUseEffect' as const;
const VISIT_COUNT               = 'data::visitCountData' as const;


const SAGA_INIT                     = 'data::saga::init' as const;
const SAGA_LOGIN_DATA               = 'data::saga::loginData' as const;
const SAGA_USER_DATA                = 'data::saga::userData' as const;
const SAGA_STORY_CATEGORY_LIST      = 'data::saga::storyCategoryList' as const;
const SAGA_COMMUNITY_CATEGORY_LIST  = 'data::saga::communityCategoryList' as const;
const SAGA_ADMIN_EMAIL              = 'data::saga::adminEmail' as const;
const SAGA_IS_APPLICATION_OS        = 'data::saga::applicationOS' as const;
const SAGA_FCM_TOKEN                = 'data::saga::fcmToken' as const;
const SAGA_MOBILE_OPEN_USE_EFFECT   = 'data::saga::mobileOpenUseEffect' as const;
const SAGA_VISIT_COUNT              = 'data::saga::visitCountData' as const;


type DataActions = 
| ReturnType<typeof init>
| ReturnType<typeof loginData>
| ReturnType<typeof userData>
| ReturnType<typeof storyCategoryList>
| ReturnType<typeof communityCategoryList>
| ReturnType<typeof adminEmail>
| ReturnType<typeof applicationOS>
| ReturnType<typeof fcmToken>
| ReturnType<typeof mobileOpenUseEffect>
| ReturnType<typeof visitCountData>

export interface DataAction extends DATA_STATE {
    type:string
    dispatch:any
}

/* 실제 상태값을 저장용 메소드 */
const init                  = ()                            => ({type:INIT})
const loginData             = ( loginData:any )             => ({type:LOGIN_DATA, loginData:loginData})
const userData              = ( userData:any )              => ({type:USER_DATA, userData:userData})
const storyCategoryList     = ( storyCategoryList:any )     => ({type:STORY_CATEGORY_LIST, storyCategoryList:storyCategoryList})
const communityCategoryList = ( communityCategoryList:any ) => ({type:COMMUNITY_CATEGORY_LIST, communityCategoryList:communityCategoryList})
const adminEmail            = ( adminEmail:string|null )    => ({type:ADMIN_EMAIL, adminEmail:adminEmail})
const applicationOS         = ( applicationOS:string|null ) => ({type:APPLICATION_OS, applicationOS:applicationOS})
const fcmToken              = ( fcmToken:string|null )      => ({type:FCM_TOKEN, fcmToken:fcmToken})
const mobileOpenUseEffect   = ( mobileOpenUseEffect:boolean ) => ({type:MOBILE_OPEN_USE_EFFECT, mobileOpenUseEffect:mobileOpenUseEffect})
const visitCountData        = ( visitCountData:any )         => ({type:VISIT_COUNT, visitCountData:visitCountData})

/* async 데이터 저장 요청 */
export const SetDataInit                = ()                            => ({type:SAGA_INIT})
export const SetLoginData               = ( dispatch:any, loginData:any ) => ({type:SAGA_LOGIN_DATA, loginData:loginData, dispatch:dispatch})
export const SetUserData                = ( userData:any )              => ({type:SAGA_USER_DATA, userData:userData})
export const SetStoryCategoryList       = ( storyCategoryList:any )     => ({type:SAGA_STORY_CATEGORY_LIST, storyCategoryList:storyCategoryList})
export const SetCommunityCategoryList   = ( communityCategoryList:any ) => ({type:SAGA_COMMUNITY_CATEGORY_LIST, communityCategoryList:communityCategoryList})
export const SetAdminEmail              = ( adminEmail:string|null )    => ({type:SAGA_ADMIN_EMAIL, adminEmail:adminEmail})
export const SetApplicationOS           = ( applicationOS:string|null ) => ({type:SAGA_IS_APPLICATION_OS, applicationOS:applicationOS})
export const SetFcmToken                = ( fcmToken:string|null )      => ({type:SAGA_FCM_TOKEN, fcmToken:fcmToken})
export const SetMobileOpenUseEffect     = ( mobileOpenUseEffect:boolean ) => ({type:SAGA_MOBILE_OPEN_USE_EFFECT, mobileOpenUseEffect:mobileOpenUseEffect})
export const SetVisitCount              = ( dispatch:any )         => ({type:SAGA_VISIT_COUNT, dispatch:dispatch})


export function* dataSaga() {
    yield takeEvery(SAGA_INIT,                      fetchInit); // 모든 INCREASE_ASYNC 액션을 처리
    yield takeEvery(SAGA_LOGIN_DATA,                fetchLoginData); 
    yield takeEvery(SAGA_USER_DATA,                 fetchUserData); 
    yield takeEvery(SAGA_STORY_CATEGORY_LIST,       fetchStoryCategoryList); 
    yield takeEvery(SAGA_COMMUNITY_CATEGORY_LIST,   fetchCommunityCategoryList); 
    yield takeEvery(SAGA_ADMIN_EMAIL,               fetchAdminEmail); 
    yield takeEvery(SAGA_IS_APPLICATION_OS,         fetchApplicationOS); 
    yield takeEvery(SAGA_FCM_TOKEN,                 fetchFcmToken); 
    yield takeEvery(SAGA_MOBILE_OPEN_USE_EFFECT,    fetchMobileOpenUseEffect); 
    yield takeEvery(SAGA_VISIT_COUNT,               fetchVisitCount); 
}


const initialState : DATA_STATE = {
    loginData:null,
    userData:null,
    storyCategoryList:null,
    communityCategoryList:null,
    adminEmail:"",
    applicationOS:null,
    fcmToken:null,
    mobileOpenUseEffect:false,
    visitCountData:{total:0, user:0}
};

function data( state : DATA_STATE = initialState, action : DataActions ) : DATA_STATE {
    switch(action.type) {
        case INIT:
            return {
                ...initialState
            }
        case LOGIN_DATA:
            return { ...state, loginData: action.loginData };      
        case USER_DATA:
            return { ...state, userData: action.userData };     
        case STORY_CATEGORY_LIST:
            return { ...state, storyCategoryList: action.storyCategoryList };   
        case COMMUNITY_CATEGORY_LIST:
            return { ...state, communityCategoryList: action.communityCategoryList };    
        case ADMIN_EMAIL:
            return { ...state, adminEmail: action.adminEmail };    
        case APPLICATION_OS:
            return { ...state, applicationOS: action.applicationOS };    
        case FCM_TOKEN:
            return { ...state, fcmToken: action.fcmToken };    
        case MOBILE_OPEN_USE_EFFECT:
            return { ...state, mobileOpenUseEffect: action.mobileOpenUseEffect };  
        case VISIT_COUNT:
            console.log("data action",action);
            return { ...state, visitCountData: action.visitCountData };  
        default:
            return state;
    }
}
export default data;

function* fetchInit( action : DataAction )                  { try { yield put(init());                      }catch(e){ console.log("fetchInit",e); } }

function* fetchUserData( action : DataAction )              { try { yield put(userData(action.userData)); }catch(e){ console.log("fetchUserData",e); } }
function* fetchStoryCategoryList( action : DataAction )     { try { yield put(storyCategoryList(action.storyCategoryList)); }catch(e){ console.log("fetchStoryCategoryList",e); } }
function* fetchCommunityCategoryList( action : DataAction ) { try { yield put(communityCategoryList(action.communityCategoryList)); }catch(e){ console.log("fetchCommunityCategoryList",e); } }
function* fetchAdminEmail( action : DataAction )            { try { yield put(adminEmail(action.adminEmail)); }catch(e){ console.log("fetchAdminEmail",e); } }
function* fetchApplicationOS( action : DataAction )         { try { yield put(applicationOS(action.applicationOS)); }catch(e){ console.log("fetchApplicationOS",e); } }
function* fetchFcmToken( action : DataAction )              { try { yield put(fcmToken(action.fcmToken)); }catch(e){ console.log("fetchFcmToken",e); } }
function* fetchMobileOpenUseEffect( action : DataAction )   { try { yield put(mobileOpenUseEffect(action.mobileOpenUseEffect)); }catch(e){ console.log("fetchMobileOpenUseEffect",e); } }



function* fetchLoginData( action : DataAction )             { 
    try { 
        yield put(loginData(action.loginData)); 

        const actionLoginData = action.loginData;
        
        if( actionLoginData ) {
            const d : APP_INTERFACE_LOGIN_DATA = {id:actionLoginData.id, pw:actionLoginData.pw, token:actionLoginData.token, code:actionLoginData.code, userData:actionLoginData};
            sendDataToApp( window, APP_INTERFACE_SEND_TYPE.LOGIN, d );
        }
        else {

            const dispatch = action.dispatch;
            const response : AxiosResponse = yield apiAxios.global.authLogout(dispatch, null);

            const logoutData : APP_INTERFACE_LOGOUT_DATA = {token:""};
            sendDataToApp( window, APP_INTERFACE_SEND_TYPE.LOGOUT, logoutData );
            
            removeCookieLoginData();
        }
    }
    catch(e){ 
        console.log("fetchLoginData",e); 
    } 
}

function* fetchVisitCount( action : DataAction ) { 
    try { 
        const dispatch = action.dispatch;
        const response : AxiosResponse = yield apiAxios.global.dataVisit(dispatch, null);
        if( response && response.data && response.data.data ) {
            yield put(visitCountData(response.data.data)); 
        }
    }catch(e){ 
        console.log("fetchVisitCount",e); 
    } 

}
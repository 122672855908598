import { TEST_VERSION } from "../../util/ConstValue";

const DOMAIN        = TEST_VERSION?"https://dailydrinketc.cafe24.com"
                        :window.location.hostname=="localhost"?
                        "https://moyoba.mycafe24.com"
                        :"https://" + window.location.hostname;

console.log("============================================");
console.log("DOMAIN : ",DOMAIN);
console.log("============================================");
//const DOMAIN        = TEST_VERSION?"":"";
const API_FOLDER    = TEST_VERSION?"/api_story":"/api";
export const URL_CERTIFICATION = "/auth/certification"

export const DEFAULT_PATH = DOMAIN + API_FOLDER + "/v1";

export const API_KEY = TEST_VERSION?"asdf123456789":"JaUinSiSc0EqzO9snLyGhlF0N46ZCZiV24PySfzI1CXzOwPm98uI2ptzsskf";

export const API_URL_GLOBAL = DEFAULT_PATH + "/global/";
export const API_URL_USER = DEFAULT_PATH + "/user/";
export const API_URL_ADMIN = DEFAULT_PATH + "/admin/";

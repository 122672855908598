import { TEST_VERSION } from "../../util/ConstValue";
import DisplayAds from "./DisplayAds";

enum SHOW_TYPE {
    SHOW_AREA = 1,
    SHOW_AD = 2,
    HIDE = 3
}

export enum AdType {
    WEB_LEFT=1,
    WEB_TOP=2,
    WEB_RIGHT_BOTTOM=3,
    WEB_MAIN_BOTTOM=4,
    WEB_MAIN_BOTTOM_RIGHT=5,
    WEB_MIDDLE_WIDTH_LONG=6,

    MOBILE_LIST=1001,
    MOBILE_MIDDLE_BOX=1002,
    MOBILE_TOP=1003
}

export enum AD_PAGE_POSITION {
    MAIN_TOP = 1,
    MAIN_BOTTOM = 2,
    LEFT = 3,
    RIGHT_BOTTOM = 4,
    PERSONAL = 5,
    SEARCH = 6,
    COMMUNITY_TOP = 10,
    STORY_TOP = 11,
    NOTICE_TOP = 20,
    NOTICE_READ_TOP = 21,
    NOTICE_READ_MIDDLE = 22,
    INQUIRY_TOP = 30,
    INQUIRY_READ_TOP = 31,
    INQUIRY_WRITE_TOP = 32,
    MY_POST_TOP = 40,
    BLOCK_USER_TOP = 50,
    POST_READ_TOP = 100,
    POST_READ_MIDDLE = 101,
    POST_WRITE_TOP = 102,

    /* 모바일 */
    MOBILE_MAIN_TOP = 1000,
    MOBILE_MAIN_MIDDLE = 1001,
    MOBILE_MAIN_LIST = 1002,
    MOBILE_COMMUNITY_TOP = 1100,
    MOBILE_COMMUNITY_LIST = 1101,
    MOBILE_STORY_TOP = 1200,
    MOBILE_STORY_LIST = 1201,
    MOBILE_INQUIRY_TOP = 1300,
    MOBILE_INQUIRY_WRITE_TOP = 1301,
    MOBILE_NOTICE_TOP = 1400,
    MOBILE_NOTICE_READ_MIDDLE = 1401,
    MOBILE_MY_POST_TOP = 1500,
    MOBILE_SEARCH_LIST = 1501,
    MOBILE_POST_READ_MIDDLE = 1600,
    MOBILE_POST_WRITE_TOP = 1601,
    MOBILE_POST_REPLY_LIST = 1602,
    MOBILE_BLOCK_USER_TOP = 1700
}

function Adsense( props:any ) {
    const {type, marginTop, height, marginBottom, className, pagePosition} = props;

    //const showType : number = pagePosition?TEST_VERSION?SHOW_TYPE.SHOW_AREA:SHOW_TYPE.SHOW_AD:SHOW_TYPE.HIDE;
    /*const isShowPosition = pagePosition==AD_PAGE_POSITION.MAIN_BOTTOM;
    const showType : number = isShowPosition?TEST_VERSION?SHOW_TYPE.SHOW_AREA:SHOW_TYPE.SHOW_AD:SHOW_TYPE.HIDE;*/
    const showType : number = TEST_VERSION?SHOW_TYPE.SHOW_AREA:SHOW_TYPE.SHOW_AD;
    
    var h : any = getHeight( type );
    
    var reHeight = h>0?h:height;

    var style : any = {};

    console.log("Adsense showType : " + showType);

    // 자리를 표시하기 위할때
    if( showType == SHOW_TYPE.SHOW_AREA ) {
        style = {
            textAlign:"center", 
            backgroundColor:"#ddd", 
            color:"#000", 
            fontSize:20
        }
    
        if( marginTop )     style["marginTop"] = marginTop;
        if( marginBottom )  style["marginBottom"] = marginBottom;   
        
        if( reHeight ) {
            style["height"] = reHeight + "px";
            style["lineHeight"] = reHeight + "px";
        }   
        return(
            <div className={className + " ad_bg"} style={style}>
                <span>광고</span>
            </div>
        )
    }

    // ADSense 광고를 표시할때
    if( showType == SHOW_TYPE.SHOW_AD ) {
    
        if( marginTop )     style["marginTop"] = marginTop;
        if( marginBottom )  style["marginBottom"] = marginBottom;  

        var adKeys = getAdKey(type);
        console.log("Adsense adKeys : " , adKeys);
        if( adKeys ) {
            console.log("draw ad");
            return(
                <div className={className + " ad_bg"} style={style}>
                    <DisplayAds 
                        adClient={adKeys.adClient}
                        adSlot={adKeys.adSlot}/>
                </div>
            )
        }
    }
    return null;
}
export default Adsense;


function getHeight( type:number ) {
    if( type == AdType.MOBILE_MIDDLE_BOX )      return 200;
    if( type == AdType.MOBILE_TOP )             return 70;
    if( type == AdType.MOBILE_LIST )            return 50;
    
    if( type == AdType.WEB_LEFT )              return 600;
    if( type == AdType.WEB_TOP )               return 100;
    if( type == AdType.WEB_RIGHT_BOTTOM )      return 300;
    if( type == AdType.WEB_MAIN_BOTTOM )       return 400;
    if( type == AdType.WEB_MAIN_BOTTOM_RIGHT ) return 400;
    if( type == AdType.WEB_MIDDLE_WIDTH_LONG ) return 100;
    return null;
}

function getAdKey( type:number ) {
    if( type == AdType.MOBILE_MIDDLE_BOX )      return {adClient:"ca-pub-3782410260692998", adSlot:"4315616508"};
    if( type == AdType.MOBILE_TOP )             return {adClient:"ca-pub-3782410260692998", adSlot:"3339260965"};
    if( type == AdType.MOBILE_LIST )            return {adClient:"ca-pub-3782410260692998", adSlot:"4428981355"};
    if( type == AdType.WEB_LEFT )               return {adClient:"ca-pub-3782410260692998", adSlot:"6511632486"};
    if( type == AdType.WEB_TOP )                return {adClient:"ca-pub-3782410260692998", adSlot:"6561116831"};
    if( type == AdType.WEB_RIGHT_BOTTOM )       return {adClient:"ca-pub-3782410260692998", adSlot:"2043287326"};
    if( type == AdType.WEB_MAIN_BOTTOM )        return {adClient:"ca-pub-3782410260692998", adSlot:"9735727472"};
    if( type == AdType.WEB_MAIN_BOTTOM_RIGHT )  return {adClient:"ca-pub-3782410260692998", adSlot:"7633142466"};
    if( type == AdType.WEB_MIDDLE_WIDTH_LONG )  return {adClient:"ca-pub-3782410260692998", adSlot:"5550491339"};
    return null;
}
/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import { ADMIN_TAB_TYPE } from '../sub/AdminTopTab';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import { makeTwoNumberStr } from '../../../util/Global';
import { TEST_VERSION } from '../../../util/ConstValue';


const AdminMain = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

	return (
		<Wrapper
			tabType={ADMIN_TAB_TYPE.HOME}
			hideSubTab={true}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default AdminMain;

enum TYPE { DAY=1, MONTH=2 };
const SHOW_COUNT = 6; // 원하는 날짜 개수보다 하루 빼서 요청 해야함

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area admin_main'>
			<VisitantComponent {...props}/>
			<VisitantComponent {...props} remove_duplicates={true}/>
		</div>
	)
}

function makeBeforeDate( currentType:TYPE, date:Date, turm:number ) {
	var newDate = new Date();
	newDate.setTime(0);
	newDate.setFullYear(date.getFullYear());
	if( currentType == TYPE.DAY )		newDate.setMonth(date.getMonth());
	else if( currentType == TYPE.MONTH)	newDate.setMonth(date.getMonth() - turm);

	if( currentType == TYPE.DAY ) newDate.setDate(date.getDate()-turm);
	return newDate;
}

function VisitantComponent( props:any ) {
	const {remove_duplicates} = props;
	const dispatch = useDispatch();

	const today = new Date();

	const [data, setData] = useState<GRAPH_DATA|null>(null);
	const [currentType, setCurrentType] = useState(TYPE.DAY);
	const [startDate, setStartDate] = useState<Date>(makeBeforeDate(currentType, today, SHOW_COUNT));
	const [endDate, setEndDate] = useState<Date>(today);

	
	useEffect(()=>{
		reqData();
		
		return(()=>{})
	},[startDate, endDate, currentType]);

	const changeCurrentType = (type:TYPE) => {
		setStartDate(makeBeforeDate(type, today, SHOW_COUNT));
		setEndDate(today);
		setCurrentType(type);
	}

	const reqData = () => {

		var start = "";
		var end = "";

		if( currentType == TYPE.DAY ) {
			start = startDate.getFullYear() + "-" + makeTwoNumberStr(startDate.getMonth()+1) + "-" + makeTwoNumberStr(startDate.getDate());
			end = endDate.getFullYear() + "-" + makeTwoNumberStr(endDate.getMonth()+1) + "-" + makeTwoNumberStr(endDate.getDate());
		}
		else if( currentType == TYPE.MONTH ) {
			start = startDate.getFullYear() + "-" + makeTwoNumberStr(startDate.getMonth()+1);
			end = endDate.getFullYear() + "-" + makeTwoNumberStr(endDate.getMonth()+1);
		}

		apiAxios.admin.dataVisit(dispatch, currentType, start, end, remove_duplicates==true?API_TYPES.BOOLEAN.TRUE:null, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				
				setGraph(resData.list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
	}

	const setGraph = ( list:any ) => {
		console.log("setGraph", list);
		var dateDic : any = {};
		var maxCount = 1;

		if( list && list.length > 0 ) {
			for( var i=0; i<list.length; i++ ) {
				var d: any = list[i];
				
				if( currentType == TYPE.DAY ) {
					var label = makeTwoNumberStr(d.month) + "." + makeTwoNumberStr(d.day);
					dateDic[label] = d.cnt*1;
				}
				else if( currentType == TYPE.MONTH ) {
					var label = d.year + "." + makeTwoNumberStr(d.month);
					dateDic[label] = d.cnt*1;
				}

				console.log("setGraph maxCount " + maxCount + ", d.cnt : " + d.cnt);
				if( maxCount < d.cnt*1 ) {
					maxCount = d.cnt*1;
				}
			}
		}
		console.log("setGraph dateDic", dateDic);

				
		var dataList : any = [];
		var date = new Date();
		date.setFullYear(startDate.getFullYear());
		date.setMonth(startDate.getMonth());
		date.setDate(startDate.getDate());

		if( currentType == TYPE.DAY ) {
			while( true ) {
				var label = makeTwoNumberStr(date.getMonth()+1) + "." + makeTwoNumberStr(date.getDate());
				var value = 0;
				if( dateDic[label] > 0 ) {
					value = dateDic[label];
				}
				dataList.push({value:value,label:label});
				date.setDate(date.getDate()+1);
				
				if( checkIsNextDay(currentType, date, endDate) ) {
					break;
				}
			}
		}
		else if( currentType == TYPE.MONTH ) {
			while( true ) {
				var label = date.getFullYear() + "." + makeTwoNumberStr(date.getMonth()+1);
				var value = 0;
				if( dateDic[label] > 0 ) {
					value = dateDic[label];
				}
				dataList.push({value:value,label:label});
				date.setMonth(date.getMonth()+1);
				
				if( checkIsNextDay(currentType, date, endDate) ) {
					break;
				}
			}
		}
		
		console.log("setGraph dataList", dataList);
		console.log("setGraph maxCount", maxCount);

		const data : GRAPH_DATA = {
			max:maxCount + (maxCount/5),
			list:dataList
		}
		setData( data );
	}

	const checkIsNextDay = ( currentType:TYPE, compareDate : Date, cDate : Date ) => {
		if( compareDate.getFullYear() > cDate.getFullYear() ) return true;
		else if( compareDate.getFullYear() < cDate.getFullYear() ) return false;
		else {
			if( compareDate.getMonth() > cDate.getMonth() ) return true;
			else if( compareDate.getMonth() < cDate.getMonth() ) return false;
			else {
				if( currentType == TYPE.DAY ) {
					if( compareDate.getDate() > cDate.getDate() ) return true;
					else {
						return false;
					}
				}
			}
		}
		return false;
	}

	return(
		<div className='visitant'>
			<div className='top'>
				<div className='title'><span>{"방문자" + (remove_duplicates==true?" (중복제거)":"")}</span></div>
				<div className={'tab_item' + (currentType==TYPE.MONTH?" tab_item_select":"")} onClick={()=>{changeCurrentType(TYPE.MONTH)}}><span>월별</span></div>
				<div className={'tab_item' + (currentType==TYPE.DAY?" tab_item_select":"")} onClick={()=>{changeCurrentType(TYPE.DAY)}}><span>일별</span></div>
			</div>
			<Graph {...props} currentType={currentType} data={data} height={180} today={today} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}/>
		</div>
	)
}

interface GRAPH_DATA {
	max:number,
	list:GRAPH_ITEM[]
}

interface GRAPH_ITEM {
	value:number,
	label:string
}

// 다음 날짜가 없는지 여부
function dontHaveNextDate( currentType:TYPE, compareDate : Date, cDate : Date ) {
	if( compareDate.getFullYear() != cDate.getFullYear() ) 	return false;
	if( compareDate.getMonth() != cDate.getMonth() ) 		return false;
	
	if( currentType == TYPE.DAY )	{
		if( compareDate.getDate() != cDate.getDate() ) 		return false;
	}

	return true;
}

// 이전 날짜가 없는지 여부
function dontHaveBeforeDate( currentType:TYPE, compareDate : Date ) {
	
	var startYear = TEST_VERSION?2023:2024;
	if( compareDate.getFullYear()>startYear ) 	return false;
	else if( compareDate.getFullYear() < startYear ) return true;
	if( compareDate.getMonth()>0)				return false;
	if( currentType == TYPE.DAY ) {
		if( compareDate.getDate() > 1 ) 		return false;
	}
	return true;
}

function Graph( props:any ) {
	const { height, loading, remove_duplicates, currentType, today, startDate, setStartDate, endDate, setEndDate} = props;
	const data : GRAPH_DATA = props.data;
	if( !data ) return null;

	const maxWidth = 500;
	const width = window.outerWidth > maxWidth?maxWidth:window.outerWidth;
	const itemWidth = data.list && data.list.length > 0 ? (width-2) / data.list.length : 0;
	const dividerMarginTop = height / 6 - 1;

	const clickedBefore = () => {
		if( dontHaveBeforeDate(currentType, startDate) == false ) {
			var sDate = makeBeforeDate(currentType, startDate, SHOW_COUNT+1);
			if( dontHaveBeforeDate(currentType, sDate) ) {
				sDate.setFullYear(TEST_VERSION?2023:2024);
				sDate.setMonth(0);
				sDate.setDate(1);

			}
			setStartDate( sDate );
			setEndDate( makeBeforeDate(currentType, startDate, 1) );
		}
	}

	const clickedNext = () => {
		if( dontHaveNextDate(currentType, endDate, today) == false ) {
			setStartDate( makeBeforeDate(currentType, endDate, -1) );
			setEndDate( makeBeforeDate(currentType, endDate, -SHOW_COUNT-1) );
		}
	}

	return(
		<div className='graph'>
			{ data.list && <>
			
				<div className='graph_value_box_bg'> 
					<div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
					</div>
					<div className='button left' onClick={clickedBefore}>
						<svg className={dontHaveBeforeDate(currentType, startDate)?"disable":""} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
					</div>
					<div className='button right' onClick={clickedNext}>
						<svg className={dontHaveNextDate(currentType, endDate, today)?"disable":""} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
					</div>
				</div>

				<div className='graph_value_box'> {
					data.list.map( (item:GRAPH_ITEM, index:number)=>{

						var turm = 23;
						var boxHeight = height;
						var marginTop = ( data.max - item.value ) / data.max * boxHeight - turm;
						//ata.max;

						return (
							<div key={index} style={{width:itemWidth}}>
								<div style={{marginTop:marginTop}}>
									<div className='value'>{item.value}</div>
									<div className='dot' style={remove_duplicates==true?{backgroundColor:"#00f"}:{}}></div>
								</div>
							</div>
						);
					})
					}
				</div>
				<div className='graph_label_box'> {
					data.list.map( (item:GRAPH_ITEM, index:number)=>{
						return (
							<div key={index} style={{width:itemWidth}}>
								<span>{item.label}</span>
							</div>
						);
					})
					}
				</div>
			</>
			}
		</div>
	)
}
/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { getDeletePostMsg, isToday, makeDateFromTimeStamp, makeTwoNumberStr } from '../../../util/Global';
import { CANT_READ_POST_ON_DELETE, POST_CUT_YEAR } from '../../../util/ConstValue';
import { PAGE_NOTICE_POST_PARAMS, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_POST_HOME_PARAMS, PAGE_POST_PARAMS, PAGE_URL, getStartPage, makeBottomPageNumberList, makeUrl } from '../../../util/Page';
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux_saga/modules';
import { AiFillPicture } from "react-icons/ai";

function CommunityPostListComponent( props:any ) {
	const {currentCategory} = props;
	
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);
	const [itemList, setItemList] = useState<any>(null);
	const [categoryIdx, setCategoryIdx] = useState(-1);
	const [totalData, setTotalData] = useState<any>(null);

    useEffect(()=>{

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		const y = l_p.get("year");
		const p = l_p.get("page");

		var newCategoryIdx = API_TYPES.CATEGORY_TYPE.ALL;
		var newYear = (new Date()).getFullYear();
		var newPage = 1;

		if( c ) try{ newCategoryIdx = parseInt(c); }catch(e){ console.log("e",e);}
		if( y ) try{ newYear = parseInt(y); }catch(e){ console.log("e",e);}
		if( p ) try{ newPage = parseInt(p); }catch(e){ console.log("e",e);}

		setItemList(null);
		setTotalData(null);

		setCategoryIdx( newCategoryIdx );
		setYear( newYear );
		setPage( newPage );

		reqNotice(newYear, newPage, newCategoryIdx);

        return(()=>{})
    },[location]);
	

    const reqNotice = ( year:number, page:number, categoryIdx:number ) => {
		const reqCategoreIdx = categoryIdx==API_TYPES.CATEGORY_TYPE.ALL||categoryIdx==API_TYPES.CATEGORY_TYPE.BEST?0:categoryIdx;

		apiAxios.global.listNotice(dispatch, API_TYPES.POST_TYPE.COMMUNITY, reqCategoreIdx, 1, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				
				const newList = !resData.list?null:resData.list.map((item:any,index:number)=>{ item.is_notice = true; return item; })
				
				console.log("reqNotice newList : " , newList);
				reqList(year, page, categoryIdx, newList);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

	const reqList = ( year:number, page:number, categoryIdx:number, noticeList:any ) => {

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				const newList = [];

				if( noticeList ) 	{ newList.push(...noticeList); }
				if( resData.list ) 	{ newList.push(...resData.list); }

				setItemList( newList );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		}

		if( categoryIdx == API_TYPES.CATEGORY_TYPE.ALL ) {
			apiAxios.global.listCommunityAllPost(dispatch, year, page, null, null, callback);
		}
		else {
			apiAxios.global.listCommunityPost(dispatch, year, page, categoryIdx, callback);
		}
	}

	var categoryName = "";
	var isBest = false;

	if( !!communityCategoryList ) {
		var categoryData = communityCategoryList.filter( (i:any) => i.idx == currentCategory );
		if( !!categoryData && categoryData.length > 0 ) {
			categoryName = categoryData[0].name;
			isBest = categoryData[0].idx == -1;
		}
	}

	return(
		<>
			<div className='post_home__list_area' id="post_home__list_area">
				<div className={'category' + (isBest?" best":"")}>{categoryName}</div>
				<table>
					<tr className='table_header'>
						<td className='title'>제목</td>
						<td className='writer'>글쓴이</td>
						<td className='insert_time'>작성일</td>
						<td className='hits'>조회</td>
						<td className='likes'>추천</td>
					</tr>
					
					<PostComponent {...props} 
						year={year}
						page={page}
						itemList={itemList}
						/>
				</table>
			</div>
			<PageComponent {...props}
				totalData={totalData}
				year={year}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA)=>{

					var params : PAGE_POST_HOME_PARAMS = {
						category:categoryIdx,
						year:year,
						page:page
					}

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}

					var url = makeUrl(PAGE_URL.COMMUNITY, params);
					navigate(url);
				}}
			/>
		</>
	)
}
export default CommunityPostListComponent;

function PageComponent(props:any) {
	const {totalData, year, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, year );
		setPageList(list);

        return(()=>{})
    },[totalData, year, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='home__post_page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 			{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 		{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 		{ numberStr = "다음"; 			className="next"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && POST_CUT_YEAR )	{ numberStr = item.page+"년"; 	className="before_year";  yearStyle={width:100}}
				else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && POST_CUT_YEAR )	{ numberStr = item.page+"년"; 	className="next_year";  	yearStyle={width:100}}

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						{ item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && item.page != -1 && POST_CUT_YEAR &&
							<span className="dot">{"·"}</span>
						}
						<span className="page" onClick={()=>{ if( onClickPage ) { onClickPage(item); } }}>
							{item.page != -1?numberStr:""}
						</span>

						{ item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && item.page != -1 && POST_CUT_YEAR &&
							<span className="dot">{"·"}</span>
						}
					</div>
				)
			})}
		</div>
	)
}

function PostComponent( props:any ) {
	const { itemList, year, page, currentCategory } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	

	const dispatch = useDispatch();
	const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[year, page]);

	

	const clickedPost = ( item:any ) => {
		
		
		// 사용가능 상태가 아니고 관리자 로그인이 아니라면 접근 불가
		if( item.use_state != API_TYPES.POST_USE_STATE.ABLE && (!loginData||loginData.is_admin!=API_TYPES.BOOLEAN.TRUE) ) {
			alert(CANT_READ_POST_ON_DELETE);
			return;
		}
		
		var fullUrl = "";

		if( item.is_notice == true ) {
			const params : PAGE_NOTICE_POST_PARAMS = {
				idx:item.idx,
				post_type:API_TYPES.POST_TYPE.COMMUNITY, 
				category:currentCategory==API_TYPES.CATEGORY_TYPE.ALL||currentCategory==API_TYPES.CATEGORY_TYPE.BEST?0:currentCategory
			}
			fullUrl = makeUrl(PAGE_URL.NOTICE_POST, params);
		}
		else {
			const params : PAGE_POST_PARAMS = {
				idx:item.idx,
				year:item.year,
				post_list_type:
					currentCategory==API_TYPES.CATEGORY_TYPE.ALL?API_TYPES.POST_LIST_TYPE.ALL_COMMUNITY
					:currentCategory==API_TYPES.CATEGORY_TYPE.BEST?API_TYPES.POST_LIST_TYPE.BEST
					:API_TYPES.POST_LIST_TYPE.NOMAL,
				post_type:API_TYPES.POST_TYPE.COMMUNITY,
				category:currentCategory==API_TYPES.CATEGORY_TYPE.BEST||currentCategory==API_TYPES.CATEGORY_TYPE.ALL?currentCategory:item.cate_idx,
				search:null,
				searchType:null
			}
			fullUrl = makeUrl(PAGE_URL.COMMUNITY_POST, params);
		}
		navigate(fullUrl);
	}

	return(
		<>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					if( item.use_state == API_TYPES.POST_USE_STATE.ABLE ) {
						return( <PostItem {...props} item={item} key={index} index={index} onClick={clickedPost}/> )
					}
					else {
						return( <DeleteItem {...props} item={item} key={index} index={index} onClick={clickedPost}/> )
					}
				})
			}
			
			{ ( !itemList || itemList.length == 0 ) &&
				<tr className='empty_item'>
					<td colSpan={5}>게시글이 없습니다</td>
				</tr>
			}
		</>
	)
}

function DeleteItem( props:any ) {
	const {item, onClick, currentCategory} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

	const showCategory = item.is_notice!=true && currentCategory == -1;

	var titleMaxWidth = 510;

	const insertTimeStr = makeInsertTimeStr( timeDate );
	var deleteMsg = getDeletePostMsg(item.use_state);

	return(
		<tr className='table_item' onClick={()=>{if(onClick) onClick(item); }}>
			<td className='title'>
				<div>
					<div className='title_body' style={{maxWidth:titleMaxWidth}}>
						{ showCategory && <span className='category delete'>{"["+item.cate_name+"]"}</span> }
						<span className='title delete'>{deleteMsg}</span>
					</div>
				</div>
			</td>			
			<td className='writer'><div><span>{"-"}</span></div></td>
			<td className='insert_time'><div><span>{insertTimeStr}</span></div></td>
			<td className='hits'><div><span>{item.hits}</span></div></td>
			<td className='likes'><div><span>{item.is_notice==true?"-":item.likes}</span></div></td>
		</tr>
	)
}

function PostItem( props:any ) {
	const {item, onClick, currentCategory} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

    const showReplyNum = item.is_notice!=true && item.reply_num && item.reply_num > 0;
	const showNew = isToday(timeDate);
	const havePicture = !!item.image01;
	const showBest = currentCategory != API_TYPES.CATEGORY_TYPE.BEST && item.best==API_TYPES.BOOLEAN.TRUE;
	const showCategory = item.is_notice!=true && ( currentCategory == API_TYPES.CATEGORY_TYPE.BEST || currentCategory == API_TYPES.CATEGORY_TYPE.ALL );

	var titleMaxWidth = 510;
	titleMaxWidth = titleMaxWidth + (item.is_notice==true?-40:0);
	titleMaxWidth = titleMaxWidth + (showBest?-40:0);
	titleMaxWidth = titleMaxWidth + (showNew?-25:0);

	titleMaxWidth = titleMaxWidth + (showReplyNum?-50:0);
	titleMaxWidth = titleMaxWidth + (havePicture?-25:0);

	const insertTimeStr = makeInsertTimeStr( timeDate );

	return(
		<tr className='table_item' onClick={()=>{if(onClick) onClick(item); }}>
			<td className='title'>
				<div>
					{ item.is_notice==true 					&& <div className='notice'><span>공지</span></div> }
					{ showBest 								&& <div className='best'><span>Best</span></div> }

					<div className='title_body' style={{maxWidth:titleMaxWidth}}>
						{ showCategory && <span className='category'>{"["+item.cate_name+"]"}</span> }
						<span className='title'>{item.title}</span>
					</div>
					
					{ showReplyNum 							&& <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
					{ havePicture 							&& <div className='picture'><AiFillPicture size={20}/></div> }
					{ showNew 								&& <div className='new'><span>N</span></div> }
				</div>
			</td>
			<td><div><span>{item.is_notice==true?"운영자":item.nickname}</span></div></td>
			<td><div><span>{insertTimeStr}</span></div></td>
			<td><div><span>{item.hits}</span></div></td>
			<td><div><span>{item.is_notice==true?"-":item.likes}</span></div></td>
		</tr>
	)
}

function makeInsertTimeStr( date:Date ) {
	if( !date ) return null;

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	
	const hour = date.getHours();
	const minute = date.getMinutes();

	// 오늘 일떄
	if( isToday(date) ) {
		return makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
	}
	else {
		return year + "." + makeTwoNumberStr(month+1) + "." + makeTwoNumberStr(day);
	}
}
import axios, {AxiosResponse, AxiosError} from "axios";
import { off } from "process";
import { ConnectInformation, RESPONSE_CODE, RESULT_CODE } from "./type";
import {API_KEY} from "./const";
import apiAxios, { API_TYPES } from "..";
import { getCookieLoginData, removeCookie, removeCookieLoginData } from "../../util/cookie_helper";
import { isMobile } from 'react-device-detect';
import reduxActions from "../../redux_saga/modules/moduleActions";
import { makePostInsertFullTime } from "../../util/Global";

export type CallFunc = (response:AxiosResponse|null, error:AxiosError|null) => void;

export const DEFAULT_LIST_PARAM = {
    count:100
    /*start:0,
    count:20,
    sort:2,
    sort_column:null*/
}

export type ListParam = {
    start:number|null,
    count:number|null,
    sort:number|null,
    sort_column:string|null
}

export const ALL_LIST_COUNT = -1;
export enum SortType {
    ASC = 1,
    DESC = 2
}

export const ALL_LIST_PARAM = {count:-1}

export function getConnectInformation() {
    const data : ConnectInformation = {
        os:getOS(),
        fcm_token:null,
        app_version:null,
        version_code:"1000",
        version_name:"1.0.0.0",
        uuid:null
    }
    return data;
}

export function getOS() {
    if( isMobile ) {
        return "mobile_web";
    }
    else {
        return "pc";
    }
}

export function getLoginToken() {
    const loginData = getCookieLoginData();
    if( loginData ) { return loginData.token; }
    return null;
}

/**
 * 받은 결과물에서 Data 값을 return 하는 메소드
 * @param response 
 * @returns 
 */
export function getResponseSuccessData( response : AxiosResponse|null ) {
    /*
        response : {
            status : 200
            data : {
                result_code:0,
                msg:"",
                data:{} <= 이 데이터를 return
            }
        }
    */

    if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
        if( response.data && response.data.data ) return response.data.data;
    }
    return null;
}

/**
 * 에러 수신 데이터 값을 return 하는 메소드
 * @param error 
 * @returns 
 */
export function getErrorResponseData( error : AxiosError|null ) {
    if( error && error.response ) {
        if( error.response.data ) return error.response.data;
    }
    return null;
}

export function makeListParam( listParam:any, otherParam:any ) {
    var param :any = {...listParam};
    if( otherParam ) param = {...listParam, ...otherParam};
    return param;
}

/**
 * 파라미터 추가
 * @param param 
 * @param paramName 
 * @param paramValue 
 * @returns 
 */
export function addParam( param:any, paramName:string, paramValue:any ) {
    if( paramValue == 0 || paramValue == "" || paramValue ) param[paramName] = paramValue;
    return param;
}

/**
 * 폼 데이터 추가
 * @param formData 
 * @param paramName 
 * @param paramValue 
 */
export function appendFormData( formData:any, paramName:string, paramValue:any ) {
    if( paramValue ) {
        if( formData == null ) formData = new FormData();
        formData.append(paramName, paramValue);
    }
    return formData;
}

/**
 * 다음 리스트 데이터 요청
 * @param dispatch 
 * @param token 
 * @param next_url 
 * @param callBack 
 * @returns 
 */
export function defaultNextListUrl( dispatch:any, token:string|null, next_url:string, callBack:CallFunc|null, skip_error:boolean = false ) {
    if( callBack )  defaultCallbackAxios( dispatch, METHOD.get, next_url, null, token, null, callBack, skip_error);
    else            return defaultAxiosPromise( dispatch, METHOD.get, next_url, null, token, null, skip_error);
}


/* 기본 Axios 메소드 */
export function defaultAxiosPromise( dispatch:any, methodS:METHOD, url:string, params:any, token:string|null, formData:FormData|null, skip_error:boolean = false ) {
    return new Promise((resolve, _) => {
        defaultCallbackAxios( dispatch, methodS, url, params, token, formData, (response:AxiosResponse|null, error:AxiosError|null)=>{
            if( response ) resolve(response);
            else if( error ) resolve(error.response);
        }, skip_error );
    })
}
// End -  기본 Axios 메소드



/* 기본 Axios 메소드 */
export function defaultCallbackAxios( dispatch:any, methodS:METHOD, url:string, params:any, token:string|null, formData:FormData|null, callBack:CallFunc, skip_error:boolean = false ) {
    console.log("== axios [" + methodS + "] " + url + " token=" + token?.substr(0,10) + "..." + token?.substr(token.length-10,10) , params, formData );
    console.log("defaultCallbackAxios params" , params );
    //console.log("== axios [" + getMethod(methodS) + "] " + url + " [" + token + "]", params, data );

    let contentType = "";
    if( formData ) {
        contentType = "multipart/form-data";
    }

    if( methodS == METHOD.get ) {
        
        if( !params ) {
            params = {};
        }
        params["X-API-Key"] = API_KEY;
        if( token ) params.Authorization = token;
        
        for (const key in params) {
            let devide = "?";
            if( url.indexOf("?") > -1 ) devide = "&"
            if( params[key] != null ) {
                url += devide+key+"="+params[key];
            }
        }
        params = null;
    }
    else {
        if( !formData ) {
            formData = new FormData();
        }
        formData.append("X-API-Key", API_KEY);
        if( token ) formData.append("Authorization", token);

        if( params ) {
            for (const key in params) {
                formData.append(key, params[key]);
            }
            params = null;
        }
    }

    console.log("== axios [" + methodS + "] " + url );

    axios({
        method: methodS,
        url: url,
        params:params,
        headers: contentType?{
            "Content-Type": contentType,
            "Access-Control-Allow-Origin": `http://localhost:3000`,
            'Access-Control-Allow-Credentials':"true",
        }:{},
        data:formData
    }).then((res:AxiosResponse) => {
        console.log("defaultCallbackAxios res : ", res);

        var reData = reParsingData(res);
        if( reData ) {
            callBack(reData, null);
        }
        else {
            callBack(res, null);
        }
    }).catch(error => {
        console.log("defaultCallbackAxios error : ", error);
        if (error.response) {
            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            
          errorFunc( dispatch, error.response.status, error.response.data, skip_error, url );
        }
        else if (error.request) {
            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
            // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
            // Node.js의 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
            errorFunc( dispatch, -1, null, skip_error, url );
        }
        else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            console.log('Error', error.message);
            errorFunc( dispatch, -1, null, skip_error, url );
        }

        callBack( null, error);
    });
}
// End -  기본 Axios 메소드

/* 데이터를 재 파싱하는 메소드 */
function reParsingData( res:AxiosResponse ){
    if(!res.data.hasOwnProperty("data")){
        console.log("defaultCallbackAxios reparsing data");
        var changeData = res.data;
        changeData = changeData.replace(/\n/gi, '\\n');
        changeData = changeData.replace(/\r/gi, '\\r');
        changeData = changeData.replace(/\t/gi, '\\t');
        changeData = changeData.replace(/\f/gi, '\\f');

        res.data = JSON.parse(changeData);
        return res;
    }
    return null;
}


const errorFunc = ( dispatch:any, status:number, data:any, skip_error:boolean = false, url:string ) => {
    if( status == RESPONSE_CODE.SUCCESS ) return;
    if( skip_error ) return;

    if( status != -1 ) {

        // 잘못된 요청
        if( status == RESPONSE_CODE.BAD_REQUEST ) {
            if( data && data.result_code == RESULT_CODE.WRONG_API_KEY ) {
                alert("유효하지 않은 API 키를 사용했습니다.");
            }
            else if( data && data.result_code == RESULT_CODE.BLACKLIST ) {
                var text = "현재 블랙리스트로 지정되었습니다.";
                var d = new Date();
                d.setTime(data.data*1000);
                text = text + "\n'" + makePostInsertFullTime( d ) + "' 부터 재사용 가능하며 로그아웃됩니다";
                alert(text);
                
                reduxActions.data.setLoginData(dispatch, null);
                reduxActions.data.setUserData(dispatch, null);
                //removeCookieLoginData();
            }
            else if( data && data.result_code == API_TYPES.RESULT_CODE.TABOO ) {
                var tabooData : any = data.data;

                var tabooStr = "";
                if( tabooData && tabooData.list && tabooData.list.length > 0 ) {
                    for( var i=0; i<tabooData.list.length; i++ ) {
                        tabooStr += "\n- " + tabooData.list[i].name;
                    }
                }
                alert("금지어가 포함되어 있습니다." + tabooStr);
            }
        }
        // 페이지를 찾을 수 없음
        else if( status == RESPONSE_CODE.NOT_FOUND ) {
            alert("요청페이지를 찾을 수 없습니다.");
        }
        // 서버 에러
        else if( status == RESPONSE_CODE.SERVER_ERROR ) {
            // 서버 에러 팝업
            alert("서버 에러!!" + data?"\ncode : "+data.result_code:"");
        }
        // 인증 에러
        else if( status == RESPONSE_CODE.UNAUTHORIZED ) {
            
            if( data && data.result_code == RESULT_CODE.PERMISSTION_DENIED ) {
                alert("요청 권한이 없습니다");
            }
            else {
                // 유저 정보 요청이 아니라면 로그아웃 처리
                if( url.indexOf("global/auth/user_data.php") == -1 ) {
                    alert("로그인 정보가 다릅니다.\n로그아웃 됩니다");

                    reduxActions.data.setLoginData(dispatch, null);
                    reduxActions.data.setUserData(dispatch, null);
                }
    
                //removeCookieLoginData();
            }
        }
    }
    else {
        // 서버 에러 팝업
    }
}

export enum METHOD {
    get="get",
    post="post"
}